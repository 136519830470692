// This file was copied from site scanner. Planning to move it to ui-common.
const AZURE_URL_PATHS = {
	API_VERSION: 'api-version=6.0',
	PROJECTS: '/_apis/projects',
	PROJECT_TYPES: '/_apis/wit/workItemTypeCategories',
	USER: 'https://app.vssps.visualstudio.com/_apis/profile/profiles/me?api-version=6.0',
	USERS: '/_apis/graph/users?api-version=6.0-preview.1',
	FIELDS: '/_apis/wit/fields?$expand=extensionFields&',
	WORK_ITEM_TYPE: '/_apis/wit/workitemtypes',
	WORK_ITEM_FIELDS: '/fields?$expand=All',
	WORK_ITEMS: '/_apis/wit/workitems/',
	BYPASS: '?bypassRules=true',
	ATTACHMENT: '/_apis/wit/attachments?fileName=screenshot.jpeg',
	PREVIEW: '-preview.3',
	CLASSIFICATIONS: '_apis/wit/classificationnodes?$depth=3'
};

export default AZURE_URL_PATHS;
