/* eslint-disable camelcase */
import { SORT_ORDER } from '@evinced-private/ui-common';
import { WfaComponentsQueryVariables } from '../WfaComponents.generated';
import {
	Wfa_Session_Components_RunsSortOrder,
	Wfa_Session_Components_RunsSortFields,
	Wfa_Session_Components_RunsTextSearchFields
} from '../../../../../types/graphql.generated';
import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';
import { IGeWfaSessionComponentsParams } from '../../../WfaDashboardService.types';
import { RUN_ID_PARAM_NAME } from '../../CommonParams';

const componentsSortFieldNamesMap: Record<string, Wfa_Session_Components_RunsSortFields> = {
	id: Wfa_Session_Components_RunsSortFields.ComponentId,
	totalPages: Wfa_Session_Components_RunsSortFields.TotalPages,
	totalIssues: Wfa_Session_Components_RunsSortFields.TotalIssues
};

const componentsSortOrderMap: Record<SORT_ORDER, Wfa_Session_Components_RunsSortOrder> = {
	[SORT_ORDER.ASC]: Wfa_Session_Components_RunsSortOrder.Ascending,
	[SORT_ORDER.DESC]: Wfa_Session_Components_RunsSortOrder.Descending
};

const componentsSearchFields = [
	Wfa_Session_Components_RunsTextSearchFields.ComponentId,
	Wfa_Session_Components_RunsTextSearchFields.TotalIssues,
	Wfa_Session_Components_RunsTextSearchFields.TotalPages,
	Wfa_Session_Components_RunsTextSearchFields.TypeNames
];

const URL_FILTER_PARAM_NAME = 'urlFilter';
const TYPE_ID_FILTER_PARAM_NAME = 'typeIdFilter';
const SEVERITY_ID_FILTER_PARAM_NAME = 'severityIdFilter';
const LEVEL_FILTER_PARAM_NAME = 'levelFilter';

export const buildSessionsComponentsQueryVariables = ({
	sessionId,
	pageSize,
	page,
	filterOptions,
	sort
}: IGeWfaSessionComponentsParams): WfaComponentsQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<WfaComponentsQueryVariables>({
		searchFields: componentsSearchFields,
		sortFieldsMap: componentsSortFieldNamesMap,
		sortOrderMap: componentsSortOrderMap
	});

	let sortFields;

	variablesBuilder
		.addPaginationParams(page, pageSize)
		.addSortParam(sort, sortFields)
		.addTextSearchParam(filterOptions?.searchValue ?? '')
		.addConditionParam(RUN_ID_PARAM_NAME, sessionId)
		.addFilterParam(URL_FILTER_PARAM_NAME, filterOptions?.urls)
		.addFilterParam(TYPE_ID_FILTER_PARAM_NAME, filterOptions?.types)
		.addFilterParam(SEVERITY_ID_FILTER_PARAM_NAME, filterOptions?.severities)
		.addFilterParam(LEVEL_FILTER_PARAM_NAME, filterOptions?.levels);

	return variablesBuilder.getVariables();
};
