import { ProductType } from 'src/interfaces/Tenant';
import { useConfiguration } from 'src/providers/configurationProvider/ConfigurationProvider';
import { useUserTenant } from 'src/providers/userTenantProvider/UserTenantProvider';
import { getProductDashboardAccessPermission } from 'src/services/CommonDashboardService';

export const usePermissions = (productType: ProductType): boolean => {
	const { tenant } = useUserTenant();
	const { getToggle } = useConfiguration();
	return getProductDashboardAccessPermission(tenant, getToggle, productType);
};
