import React, { FC } from 'react';
import { EvSnippetView, HeadingLevelRange } from '@evinced-private/ui-common';
import { renderPart } from '../issue-markdown-text-block/IssueMarkdownTextBlock';
import NotGroupedComponent from '../../../consts/NotGroupedComponent';
import CopyToClipboard from '../copy-to-clipboard/CopyToClipboard';

interface IElementDetailsBlockProps {
	elementId: string;
	componentId: string;
	selector: string;
	subheadingsLevel: HeadingLevelRange;
	domSnippet: string;
}

export const ElementDetailsBlock: FC<IElementDetailsBlockProps> = ({
	elementId,
	componentId,
	selector,
	subheadingsLevel,
	domSnippet
}): JSX.Element => {
	return (
		<div className="component-details" key={elementId}>
			{renderPart(
				'Component:',
				componentId === NotGroupedComponent.ID ? NotGroupedComponent.DISPLAY_NAME : componentId,
				subheadingsLevel
			)}
			{selector &&
				renderPart(
					'Selector:',
					<div className="selector-with-copy">
						{selector}
						<CopyToClipboard renderJustIcon textToCopy={selector} />
					</div>,
					subheadingsLevel
				)}
			{domSnippet ? (
				<div className="snippet" aria-label="DOM Snippet">
					<EvSnippetView title="" snippet={domSnippet} codeType="html" />
				</div>
			) : null}
		</div>
	);
};
