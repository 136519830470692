import { ConditionId } from 'src/types/graphql.generated';
import { RUN_ID_PARAM_NAME } from 'src/services/wfa-dashboard/queries/CommonParams';
import { QueryVariablesBuilder } from './QueryVariablesBuilder';

type TRunIdQueryVariable = {
	runId: ConditionId;
};

export const buildRunIdParam = (runId: string): TRunIdQueryVariable => {
	const variablesBuilder = new QueryVariablesBuilder<TRunIdQueryVariable>();
	return variablesBuilder.addConditionParam(RUN_ID_PARAM_NAME, runId).getVariables();
};
