import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WFA_SESSION_FILTER_OPTIONS } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaSessionIssuesFilterOptions } from 'src/services/wfa-dashboard/WfaDashboardService';
import { TWfaSessionIssuesFilterOptions } from 'src/services/wfa-dashboard/WfaDashboardService.types';

export const useWfaSessionFilterOptionsQuery = (
	sessionId: string
): UseQueryResult<TWfaSessionIssuesFilterOptions> => {
	const sessionDetailsQuery = useQuery([WFA_SESSION_FILTER_OPTIONS, sessionId], () =>
		getWfaSessionIssuesFilterOptions(sessionId)
	);

	return sessionDetailsQuery;
};
