import { IGetHTMLReportArgs, IGetMfaSessionsArgs } from 'src/pages/mfa-dashboard/mfa-types';
import ServerApi from 'src/api/ServerApi';
import API_URL_PATHS from 'src/consts/ApiUrlPaths';
import {
	IGetMSdkSessionsResponse,
	IMSdkEndpointResponse
} from '../../pages/mobile-sdk-dashboard/mobile-sdk-types';

// TODO: renanme args type to someting more generic
const getMobileSdkSessions = async ({
	tenantId,
	productId
}: IGetMfaSessionsArgs): Promise<IGetMSdkSessionsResponse[]> => {
	const res: IGetMSdkSessionsResponse[] = await ServerApi.apiCall(
		'GET',
		`tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions`
	);
	return res;
};

// TODO: renanme args type to someting more generic
const getMobileSdkSessionReport = async ({
	tenantId,
	productId,
	sessionId
}: IGetHTMLReportArgs): Promise<string> => {
	const res: IMSdkEndpointResponse = await ServerApi.apiCall(
		'GET',
		`/tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions/${sessionId}/html-report`
	);
	return res.link;
};

export default {
	getMobileSdkSessions,
	getMobileSdkSessionReport
};
