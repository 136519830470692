import { BarDatum } from '@evinced-private/ui-common';
import { SeverityCountType } from '../../../../../../../types/wfa/SeverityCountType';

interface SeverityChartDatum extends BarDatum {
	label: string;
	count: number;
}

export const prepareDataForSeverityChart = (
	severitiesCount: SeverityCountType[]
): SeverityChartDatum[] => {
	return (
		severitiesCount
			// return only severities with count > 0
			.filter((severityCount) => severityCount.count > 0)
			.map((severityCount) => {
				return {
					id: severityCount.id,
					label: severityCount.name,
					count: severityCount.count,
					severity: severityCount.severity
				};
			})
	);
};
