import {
	IFilterDefinition,
	ISelectFilterDefinition,
	ITextFilterDefinition
} from '@evinced-private/ui-common';

const searchFilter: ITextFilterDefinition = {
	id: 'searchValue',
	label: 'Search',
	filterType: 'text',
	defaultValue: '',
	componentProps: {
		placeholder: 'Search',
		className: 'search-filter'
	}
};
const urlsFilter: ISelectFilterDefinition = {
	id: 'urls',
	label: 'url',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select URL',
		allSelectedPlaceholder: 'All URLs',
		someItemsSelectedText: (numberOfItems) => `URLs (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'wfa-session-filter-dropdown wfa-session-url-filter'
	}
};
const severitiesFilter: ISelectFilterDefinition = {
	id: 'severities',
	label: 'severities',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select Severity',
		allSelectedPlaceholder: 'All Severities',
		someItemsSelectedText: (numberOfItems) => `Severities (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'wfa-session-filter-dropdown'
	}
};
const typesFilter: ISelectFilterDefinition = {
	id: 'types',
	label: 'types',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select Type',
		allSelectedPlaceholder: 'All Types',
		someItemsSelectedText: (numberOfItems) => `Types (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'wfa-session-filter-dropdown'
	}
};
const levelsFilter: ISelectFilterDefinition = {
	id: 'levels',
	label: 'levels',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select Level',
		allSelectedPlaceholder: 'All Levels',
		someItemsSelectedText: (numberOfItems) => `Levels (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'wfa-session-filter-dropdown'
	}
};

export const wfaSingleSessionFilterDefinitions: IFilterDefinition[] = [
	searchFilter,
	urlsFilter,
	severitiesFilter,
	typesFilter,
	levelsFilter
];
