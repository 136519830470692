import { OptionType } from '@evinced-private/ui-common';
import { UsageDataQueryVariables } from '../UsageData.generated';
import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';
import { testsSearchFields } from '../../Tests/helpers/buildVariables';

const DAYS_AMOUNT_FILTER_PARAM_KEY = 'daysAmountFilter';
const DAYS_AMOUNT_X2_FILTER_PARAM_KEY = 'daysAmountx2Filter';

export const buildUsageDataVariables = (
	testPeriod?: OptionType,
	searchValue?: string
): UsageDataQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<UsageDataQueryVariables>({
		searchFields: testsSearchFields
	});

	variablesBuilder.addTextSearchParam(searchValue ?? '');

	if (testPeriod?.value && testPeriod.value !== 'all' && typeof testPeriod.value === 'number') {
		variablesBuilder
			.addConditionParam(DAYS_AMOUNT_FILTER_PARAM_KEY, testPeriod.value)
			.addConditionParam(DAYS_AMOUNT_X2_FILTER_PARAM_KEY, 2 * testPeriod.value);
	}
	return variablesBuilder.getVariables();
};
