import React, { FC, useMemo } from 'react';
import { EvSection, EvSpinner, EvTitle } from '@evinced-private/ui-common';
import classNames from 'classnames';
import { ReportInfo } from 'src/components/report-info/ReportInfo';
import CopyToClipboard from 'src/components/common/copy-to-clipboard/CopyToClipboard';
import { getCurrentWFAFullUrl } from 'src/services/ShareableLinksService';
import { useBreadcrumbRoutes } from 'src/providers/breadcrumbsProvider/BreadcrumbsProvider';
import { usePermissions } from 'src/hooks/usePermissions';
import { ProductType } from 'src/interfaces/Tenant';
import LinkIcon from '../../../../components/icons/LinkIcon.svg';
import {
	WFA_SESSION_HEADER_DAID,
	WFA_SESSION_TITLE_DAID,
	WFA_SESSION_COPY_URL_BUTTON_DAID
} from '../../WfaDashboardAutomationIds';
import { useWfaSessionDetailsQuery } from '../../hooks/useWfaSessionDetailsQuery';
import { getWfaBreadcrumbRoutes } from '../../WfaDashboardHelpers';
import { getWfaInfoItems } from './WfaSingleSessionHeaderHelper';
import './WfaSingleSessionHeader.scss';

interface IWfaSessionHeaderProps {
	sessionId: string;
}

export const WfaSingleSessionHeader: FC<IWfaSessionHeaderProps> = ({ sessionId }) => {
	const sessionDetailsQuery = useWfaSessionDetailsQuery(sessionId);
	const { name } = sessionDetailsQuery.data ?? {};

	const breadcrumbRoutes = useMemo(
		() => getWfaBreadcrumbRoutes(sessionId, name),
		[sessionId, name]
	);

	const isPlatformEnabledForWfa = usePermissions(ProductType.WEB_FLOW_ANALYZER);
	// do not display breadcrumbs if the user has no access to the dashboard
	useBreadcrumbRoutes(isPlatformEnabledForWfa ? breadcrumbRoutes : []);

	return sessionDetailsQuery.isLoading ? (
		<EvSpinner />
	) : (
		<div className="wfa-single-session-header" data-automation-id={WFA_SESSION_HEADER_DAID}>
			<div className="title-container">
				<EvTitle
					titleText={name}
					headingLevel={1}
					className="main-title page-heading"
					dataAutomationId={WFA_SESSION_TITLE_DAID}
				/>
				<CopyToClipboard
					renderJustIcon
					renderTooltip
					textToCopy={getCurrentWFAFullUrl(sessionId)}
					className="copy-link-button"
					iconTitle="Copy Session URL"
					icon={LinkIcon}
					dataAutomationId={WFA_SESSION_COPY_URL_BUTTON_DAID}
				/>
			</div>
			<EvSection
				ariaLabel={`Session ${name} info`}
				skipLinkId="session-info-section"
				ariaLive="polite"
				className={classNames('dashboard-section', 'web-sdk-test-info-section')}
			>
				<ReportInfo
					titleText="Session Info"
					reportInfo={getWfaInfoItems()}
					reportDetails={sessionDetailsQuery.data}
				/>
			</EvSection>
		</div>
	);
};
