import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';
import RoutesHelper from '../../helpers/RoutesHelper';
import NotFound404Page from '../not-found-404-page/NotFound404Page';
import { ProductType } from '../../interfaces/Tenant';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { hasLicenseForProduct } from '../../services/CommonDashboardService';
import Unauthorized403Page from '../unauthorized-403-page/Unauthorized403Page';
import HtmlReport from '../mfa-dashboard/components/HTMLReport/HtmlReport';
import { getMsdkReportIdParam } from './msdk-dashboard-helper';

const productType = ProductType.MOBILE_FLOW_ANALYZER;

const MobileSdkRouter: FC = () => {
	const basePath = RoutesHelper.getMobileSdkTestReportPathname();
	const { tenant } = useUserTenant();
	const hasLicense = hasLicenseForProduct(tenant, productType);

	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={basePath}
					render={({ location }) => {
						return hasLicense ? (
							<HtmlReport sessionId={getMsdkReportIdParam(location)} msdkReport />
						) : (
							<Unauthorized403Page />
						);
					}}
				/>
				<Route render={() => <NotFound404Page />} />
			</Switch>
		</Router>
	);
};

export default MobileSdkRouter;
