import React from 'react';
import { EvTitle, EvTooltip, HeadingLevelRange } from '@evinced-private/ui-common';
import classNames from 'classnames';
import { Tag } from '../tag/Tag';
import { HorizontalDivider } from '../horizontal-divider/HorizontalDivider';
import './TagsBlock.scss';

export interface ITagGroup {
	tags: {
		name: string;
		value: string;
		maxChars?: number;
	}[];
	title?: string;
}

interface ITagsBlockProps {
	tagGroups: ITagGroup[];
	isThreeColumns?: boolean;
	titlesHedingLevel?: HeadingLevelRange;
}

const tooltipId = 'tags-block-tooltip';

export const TagsBlock: React.FC<ITagsBlockProps> = ({
	tagGroups,
	isThreeColumns,
	titlesHedingLevel
}) => {
	const renderTagGroup = ({ tags, title }: ITagGroup, index): JSX.Element => {
		const needsHorizontalDivider =
			index !== tagGroups.length - 1 && !!tags.length && !!tagGroups[index + 1]?.tags?.length;

		return (
			!!tags.length && (
				<>
					{!!title?.length && (
						<EvTitle
							titleText={title}
							headingLevel={titlesHedingLevel ?? 3}
							className="group-title"
						/>
					)}
					<div
						className={classNames('tags-grid', {
							'three-columns': isThreeColumns
						})}
					>
						{tags.map((tag) => (
							<Tag
								key={tag.name}
								text={tag.value}
								label={tag.name}
								maxChars={tag.maxChars}
								tooltipId={tooltipId}
							/>
						))}
					</div>
					{needsHorizontalDivider && <HorizontalDivider />}
				</>
			)
		);
	};

	return (
		<div className="tags-block">
			{tagGroups.map(renderTagGroup)}
			<EvTooltip id={tooltipId} place="bottom" type="info" />
		</div>
	);
};
