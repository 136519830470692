import React, { FC, useCallback, useEffect, useState } from 'react';
import { NewEvincedLogo } from '@evinced-private/ui-common';
import { useLocation } from 'react-router-dom';
import RoutesHelper from '../../helpers/RoutesHelper';
import useUniversalLogin from '../../hooks/useUniversalLogin';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import EvHeader from '../common/ev-header/EvHeader';
import EvBreadcrumbs, { IEvBreadcrumb } from '../header-navigation/EvBreadcrumbs';
import { getMfaDashboardBreadcrumbsRoutes } from '../../pages/mfa-dashboard/mfa-dashboard-helpers/mfa-dashboard-helpers';
import { getMsdkDashboardBreadcrumbsRoutes } from '../../pages/mobile-sdk-dashboard/msdk-dashboard-helper';
import { getWebSdkDashboardBreadcrumbsRoutes } from '../../pages/web-sdk-dashboard/web-sdk-helpers';
import { useRouteParams } from '../../providers/routeParamsProvider/RouteParamsProvider';
import { getProductDashboardAccessPermission } from '../../services/CommonDashboardService';
import { ProductType } from '../../interfaces/Tenant';
import { useConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import { useBreadcrumbRoutes } from '../../providers/breadcrumbsProvider/BreadcrumbsProvider';

interface IHeaderProps {
	isAuthenticatedPage: boolean;
}

const Header: FC<IHeaderProps> = ({ isAuthenticatedPage }: IHeaderProps) => {
	const [shouldShowInviteOption, setShouldShowInviteOption] = useState<boolean>(false);
	const location = useLocation();
	const routeParams = useRouteParams(); // read variables from the path
	const { isAuthenticated } = useUniversalLogin();
	const { tenant } = useUserTenant();
	const { getToggle } = useConfiguration();
	const { breadcrumbRoutes } = useBreadcrumbRoutes();

	const updateShouldShowInviteOption = useCallback((): void => {
		const isUserAuthenticated = isAuthenticated();
		if (!isAuthenticatedPage || !isUserAuthenticated) {
			setShouldShowInviteOption(false);
			return;
		}
		setShouldShowInviteOption(!!tenant);
	}, [isAuthenticated, isAuthenticatedPage, tenant]);

	useEffect(() => {
		if (shouldShowInviteOption) {
			return;
		}
		updateShouldShowInviteOption();
	}, [location, shouldShowInviteOption, updateShouldShowInviteOption]);

	const getBreadcrumbsRoutes = (): IEvBreadcrumb[] => {
		if (
			location.pathname === RoutesHelper.getMfaSessionReportPathname() &&
			getProductDashboardAccessPermission(tenant, getToggle, ProductType.MOBILE_FLOW_ANALYZER)
		) {
			return getMfaDashboardBreadcrumbsRoutes(location);
		}
		if (
			location.pathname === RoutesHelper.getMobileSdkTestReportPathname() &&
			getProductDashboardAccessPermission(tenant, getToggle, ProductType.MOBILE_SDK)
		) {
			return getMsdkDashboardBreadcrumbsRoutes(location);
		}
		if (
			location.pathname.includes(RoutesHelper.getWebSdkDashboardTestPath()) &&
			getProductDashboardAccessPermission(tenant, getToggle, ProductType.WEB_SDK)
		) {
			return getWebSdkDashboardBreadcrumbsRoutes(location, routeParams);
		}

		return breadcrumbRoutes ?? [];
	};

	const breadcrumbsRoutes: IEvBreadcrumb[] = getBreadcrumbsRoutes();

	return (
		<EvHeader
			isAuthenticatedPage={isAuthenticated}
			logoIcon={NewEvincedLogo}
			onLogoClickUrl={RoutesHelper.getHomepagePath()}
			className="dashboard-header"
			switchButtonProps={null}
		>
			{breadcrumbsRoutes.length > 0 ? (
				<EvBreadcrumbs breadcrumbsRoutes={breadcrumbsRoutes} />
			) : null}
		</EvHeader>
	);
};

export default Header;
