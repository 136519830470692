import React, { FC, useMemo } from 'react';
import omit from 'lodash-es/omit';
import {
	EvFilters,
	EvSection,
	EvSpinner,
	EvSwitch,
	EvTitle,
	OptionType,
	TABLE_FILTERS_SR_SUMMARY,
	TABLE_SR_SUMMARY_IDS,
	useSkipLinks,
	IFilterValues
} from '@evinced-private/ui-common';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { flattenObject } from 'src/helpers/ObjectsHelper';
import { IFilterOptions } from '../../components/data-filter/DataFilterHelper';
import SummaryPane from '../../components/common/summary-pane/SummaryPane';
import PlatformTable from '../../components/common/platform-table/PlatformTable';
import { HorizontalDivider } from '../../components/common/horizontal-divider/HorizontalDivider';
import {
	getWebSdkUsageData,
	getWebSdkTests,
	getWebSdkTestLabels
} from '../../services/web-sdk-dashboard/WebSdkDashboardService';
import { getPropertiesMap } from '../../services/properties/PropertiesService';
import { useTableState } from '../../providers/dashboard/TableStateProvider';
import {
	showRecentTestKey,
	showTestPathKey
} from '../../providers/dashboard/TablesCustomStateHelper';
import {
	PROPERTIES,
	USAGE_DATA,
	WEB_SDK_TESTS,
	WEB_SDK_TEST_LABELS
} from '../../providers/reactQueryProvider/QueryKeys';
import { TWebSdkTestsResult } from '../../services/web-sdk-dashboard/WebSdkDashboardService.types';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import { queryClient } from '../../providers/reactQueryProvider/ReactQueryProvider';
import { SummaryItem } from '../../interfaces/SummaryItem';
import {
	WEB_SDK_TEST_ISSUES_TABLE_ID,
	WEB_SDK_TEST_TABLE_ID
} from '../dashboards-container/dashboard_consts';
import { getWebSdkTestsColumns } from './web-sdk-table-helpers';
import { WebSdkTestsPageError } from './error-pages/WebSdkTestsPageError';
import {
	TESTS_PERIOD_OPTIONS,
	TESTS_REFETCH_INTERVAL,
	webSdkTestsFilterDefinitions
} from './web-sdk-consts';
import { populateTestsTableDataWithDisplayData } from './web-sdk-helpers';
import {
	SHOW_ONLY_LATEST_TESTS_TOGGLE_ID,
	WEB_SDK_TESTS_PAGE_FILTERS_SECTION_ID,
	WEB_SDK_TESTS_PAGE_MAIN_TITLE_ID,
	WEB_SDK_TESTS_PAGE_TABLE_SECTION_ID,
	WEB_SDK_TESTS_PAGE_TABLE_TITLE_ID,
	WEB_SDK_TESTS_PAGE_USAGE_SECTION_ID,
	WEB_SDK_TESTS_PAGE_USAGE_TITLE_ID
} from './web-sdk-data-automation-ids';
import './WebSdkTestsPage.scss';

const WebSdkPageTitle = 'Web SDK report';

const enableWebSdkProperties = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES
);

const WebSdkTestsPage: FC = () => {
	const { onTableParamStatusChange, getTableStateById, onFilterChange } = useTableState();
	const tableState = getTableStateById(WEB_SDK_TEST_TABLE_ID);
	const issuesTableState = getTableStateById(WEB_SDK_TEST_ISSUES_TABLE_ID);
	const { filters, tableSort, paginationInfo, customParams } = tableState;
	const showRecentTest = customParams?.[showRecentTestKey] ?? false;
	const showTestPath = customParams?.[showTestPathKey] ?? false;

	const testLabelsQuery = useQuery(
		[WEB_SDK_TEST_LABELS],
		async (): Promise<OptionType[]> => getWebSdkTestLabels(),
		{
			refetchInterval: TESTS_REFETCH_INTERVAL
		}
	);

	const propertiesQuery = useQuery([PROPERTIES], getPropertiesMap, {
		enabled: enableWebSdkProperties
	});

	const propertiesOptions = useMemo((): OptionType[] => {
		const options: OptionType[] = [];
		if (enableWebSdkProperties && !propertiesQuery.isLoading) {
			propertiesQuery.data?.forEach((name, id) => {
				options.push({
					value: id,
					label: name
				});
			});
		}

		return options;
	}, [propertiesQuery]);

	const usageDataQuery = useQuery(
		[USAGE_DATA, showRecentTest, filters?.testPeriod, filters?.searchValue],
		async (): Promise<SummaryItem[]> =>
			getWebSdkUsageData(showRecentTest, filters?.testPeriod?.[0], filters?.searchValue),
		{
			refetchInterval: TESTS_REFETCH_INTERVAL
		}
	);

	useSkipLinks(!usageDataQuery.isLoading);

	const testsQuery = useQuery(
		[
			WEB_SDK_TESTS,
			showRecentTest,
			filters,
			tableSort?.[0],
			paginationInfo.page,
			paginationInfo.sizePerPage
		],
		async (): Promise<TWebSdkTestsResult> =>
			getWebSdkTests({
				isRecentTests: showRecentTest,
				filterOptions: filters,
				sort: tableSort?.[0],
				page: paginationInfo.page,
				pageSize: paginationInfo.sizePerPage,
				isShowTestPath: showTestPath
			}),
		{
			keepPreviousData: true,
			refetchInterval: TESTS_REFETCH_INTERVAL,
			staleTime: TESTS_REFETCH_INTERVAL
		}
	);

	const showTestPathToggleButton = useMemo(
		(): JSX.Element => (
			<EvSwitch
				label="Show test path"
				checked={showTestPath}
				onChange={() => {
					onTableParamStatusChange(WEB_SDK_TEST_TABLE_ID, showTestPathKey, () => !showTestPath);
					queryClient.invalidateQueries({ queryKey: [WEB_SDK_TESTS], refetchType: 'none' });
				}}
			/>
		),
		[showTestPath, onTableParamStatusChange]
	);

	const total = testsQuery.data?.total ?? 0;
	const unfilteredTotal = testsQuery.data?.unfilteredTotal ?? 0;

	const rows = useMemo(
		() =>
			populateTestsTableDataWithDisplayData(
				testsQuery.data?.rows ?? [],
				showTestPath,
				propertiesQuery.data
			),
		[testsQuery.data?.rows, showTestPath, propertiesQuery.data]
	);

	const handleApply = (appliedFilters: IFilterValues): void => {
		const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
		onFilterChange(WEB_SDK_TEST_TABLE_ID, flatFilters);

		// set properties in issues table too
		onFilterChange(WEB_SDK_TEST_ISSUES_TABLE_ID, {
			...(issuesTableState?.filters ?? {}),
			properties: flatFilters.properties ?? []
		});
	};

	if (testsQuery.isError || usageDataQuery.isError || propertiesQuery.isError) {
		return <WebSdkTestsPageError />;
	}

	if (!testsQuery.isLoading && !testsQuery.isPreviousData && unfilteredTotal === 0) {
		return <WebSdkTestsPageError isNoData />;
	}

	const filterOptions = {
		labels: testLabelsQuery.data ?? [],
		properties: propertiesOptions ?? [],
		testPeriod: TESTS_PERIOD_OPTIONS
	};

	const initialValues = {
		text: { searchValue: filters.searchValue },
		select: omit(filters, 'searchValue')
	};

	const isPropertiesLoading = enableWebSdkProperties && propertiesQuery.isLoading;

	const isFilterOptionsLoading = isPropertiesLoading || testLabelsQuery.isLoading;

	const isTableLoading =
		isPropertiesLoading ||
		testsQuery.isLoading ||
		testsQuery.isPreviousData ||
		(testsQuery.isStale && testsQuery.isRefetching);

	return (
		<div className="web-sdk-tests-page">
			<EvTitle
				titleText={WebSdkPageTitle}
				dataAutomationId={WEB_SDK_TESTS_PAGE_MAIN_TITLE_ID}
				headingLevel={1}
				className="main-title page-heading"
			/>
			<EvSection
				ariaLabel="WEB SDK All Tests filters"
				ariaDescribedby={TABLE_FILTERS_SR_SUMMARY}
				ariaLive="polite"
				skipLinkId="filters-section"
				className={classNames('dashboard-section', 'web-sdk-tests-filters-section')}
				dataAutomationId={WEB_SDK_TESTS_PAGE_FILTERS_SECTION_ID}
			>
				{isFilterOptionsLoading ? (
					<EvSpinner />
				) : (
					<div className="filters-area">
						<div className="filter-line">
							<EvFilters
								className="data-filters"
								onApply={handleApply}
								onReset={handleApply}
								initialValues={initialValues}
								isFilterOptionsLoaded={!isFilterOptionsLoading}
								filterOptions={filterOptions}
								filterDefinitions={webSdkTestsFilterDefinitions}
							/>
						</div>
						<EvSwitch
							dataAutomationId={SHOW_ONLY_LATEST_TESTS_TOGGLE_ID}
							onChange={() =>
								onTableParamStatusChange(
									WEB_SDK_TEST_TABLE_ID,
									'showRecentTest',
									() => !showRecentTest
								)
							}
							checked={showRecentTest}
							label="Show only the last report per test"
							className="show-only-most-recent-results-toggle"
						/>
						<span className="sr-only" aria-live="assertive">
							{showTestPath
								? 'Showing full test paths in test name column'
								: 'Not showing test path in test name column'}
						</span>
					</div>
				)}
			</EvSection>
			<HorizontalDivider />
			<EvSection
				ariaLabel="Web SDK usage"
				skipLinkId="usage-section"
				className={classNames('dashboard-section', 'usage-section')}
				ariaLive="polite"
				dataAutomationId={WEB_SDK_TESTS_PAGE_USAGE_SECTION_ID}
			>
				<EvTitle
					titleText="Usage"
					headingLevel={2}
					className="page-heading"
					dataAutomationId={WEB_SDK_TESTS_PAGE_USAGE_TITLE_ID}
				/>
				{usageDataQuery.isLoading ? (
					<EvSpinner />
				) : (
					<SummaryPane
						items={usageDataQuery.data}
						shouldHaveSkipLink={false}
						className="base-summary-pane"
						ariaLabel="Web SDKs usage summary"
					/>
				)}
			</EvSection>
			<EvSection
				ariaLabel="WEB SDK Tests Table"
				ariaLive="polite"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				skipLinkId="main-section"
				className={classNames('dashboard-section', 'table-section')}
				dataAutomationId={WEB_SDK_TESTS_PAGE_TABLE_SECTION_ID}
			>
				<EvTitle
					titleText="All tests"
					headingLevel={2}
					className="page-heading"
					dataAutomationId={WEB_SDK_TESTS_PAGE_TABLE_TITLE_ID}
				/>
				<PlatformTable
					isTableLoading={isTableLoading}
					tableId={WEB_SDK_TEST_TABLE_ID}
					tableData={rows}
					totalPaginationableResults={total}
					totalCount={unfilteredTotal}
					className="web-sdk-tests-table"
					persistTableState
					columns={getWebSdkTestsColumns()}
					topRowActions={showTestPathToggleButton}
					options={{
						keyField: 'runId',
						title: 'Web SDK Tests',
						caption: 'Web SDK table',
						pagination: true,
						dataType: 'tests',
						remote: true
					}}
				/>
			</EvSection>
		</div>
	);
};

export default WebSdkTestsPage;
