import React, { FC, createContext, useState, useContext, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash-es';
import { TableSortOption } from '@evinced-private/ui-common';
import logger from '../../services/Logger';
import DataFilterHelper, { IFilterOptions } from '../../components/data-filter/DataFilterHelper';
import localStorageApi from '../../api/LocalStorageApi';
import {
	MFA_SESSIONS_TABLE_ID,
	MSDK_SESSIONS_TABLE_ID,
	WEB_SDK_TEST_ISSUES_TABLE_ID,
	WEB_SDK_TEST_TABLE_ID,
	WFA_SESSION_ISSUES_TABLE_ID,
	WFA_SESSION_COMPONENTS_TABLE_ID,
	WFA_SESSIONS_TABLE_ID
} from '../../pages/dashboards-container/dashboard_consts';
import { CustomConfigTableType, getDefualCustomState } from './TablesCustomStateHelper';

interface ITablePagination {
	page: number;
	sizePerPage: number;
}

interface ITableStateValue<T> {
	filters: IFilterOptions;
	tableSort: Array<TableSortOption>;
	paginationInfo: ITablePagination;
	customParams?: T;
}

type ITableState = {
	[id: string]: ITableStateValue<CustomConfigTableType>;
};

const TABLES_STATE = 'TABLES-STATE';
const DEFAULT_PAGE = 1;
const DEFAULT_SIZE_PER_PAGE = 10;

const getDefaultState = (tableId: string): ITableStateValue<CustomConfigTableType> => {
	return {
		filters: DataFilterHelper.emptyFilterOptions(tableId),
		tableSort: null,
		paginationInfo: {
			page: DEFAULT_PAGE,
			sizePerPage: DEFAULT_SIZE_PER_PAGE
		},
		customParams: getDefualCustomState(tableId)
	};
};

export interface ITableStateContext {
	tablesState: ITableState;
	updateTableState: (
		tableId: string,
		newStateValue: ITableStateValue<CustomConfigTableType>
	) => void;
	getTableStateById: (tableId: string) => ITableStateValue<CustomConfigTableType>;
	onTablePageOrPageSizeChange: (tableId: string, pagination: ITablePagination) => void;
	onTableParamStatusChange: (
		tableId: string,
		paramKey: keyof CustomConfigTableType,
		paramMethod: () => CustomConfigTableType[typeof paramKey]
	) => void;
	onTableSort: (tableId: string, sort: TableSortOption[]) => void;
	onFilterChange: (tableId: string, filters: IFilterOptions) => void;
}

const loadTableStateFromStorage = (): ITableState => {
	try {
		return (
			localStorageApi.getFromLocalStorage<ITableState>(TABLES_STATE) ?? {
				[MSDK_SESSIONS_TABLE_ID]: getDefaultState(MSDK_SESSIONS_TABLE_ID),
				[MFA_SESSIONS_TABLE_ID]: getDefaultState(MFA_SESSIONS_TABLE_ID),
				[WEB_SDK_TEST_TABLE_ID]: getDefaultState(WEB_SDK_TEST_TABLE_ID),
				[WEB_SDK_TEST_ISSUES_TABLE_ID]: getDefaultState(WEB_SDK_TEST_ISSUES_TABLE_ID),
				[WFA_SESSIONS_TABLE_ID]: getDefaultState(WFA_SESSIONS_TABLE_ID),
				[WFA_SESSION_COMPONENTS_TABLE_ID]: getDefaultState(WFA_SESSION_COMPONENTS_TABLE_ID),
				[WFA_SESSION_ISSUES_TABLE_ID]: getDefaultState(WFA_SESSION_ISSUES_TABLE_ID)
			}
		);
	} catch (err) {
		logger.error('Failed to load table state from storage', err);
		return {
			[MSDK_SESSIONS_TABLE_ID]: getDefaultState(MSDK_SESSIONS_TABLE_ID),
			[MFA_SESSIONS_TABLE_ID]: getDefaultState(MFA_SESSIONS_TABLE_ID),
			[WEB_SDK_TEST_TABLE_ID]: getDefaultState(WEB_SDK_TEST_TABLE_ID),
			[WEB_SDK_TEST_ISSUES_TABLE_ID]: getDefaultState(WEB_SDK_TEST_ISSUES_TABLE_ID),
			[WFA_SESSIONS_TABLE_ID]: getDefaultState(WFA_SESSIONS_TABLE_ID),
			[WFA_SESSION_COMPONENTS_TABLE_ID]: getDefaultState(WFA_SESSION_COMPONENTS_TABLE_ID),
			[WFA_SESSION_ISSUES_TABLE_ID]: getDefaultState(WFA_SESSION_ISSUES_TABLE_ID)
		};
	}
};

const TableStateContext = createContext({} as ITableStateContext);
export const useTableState = (): ITableStateContext => useContext(TableStateContext);

export const TableStateProvider: FC = ({ children }) => {
	const [tablesState, setTableState] = useState<ITableState>(() => loadTableStateFromStorage());

	const updateTableState = useCallback(
		(tableId: string, newStateValue: ITableStateValue<CustomConfigTableType>): void => {
			setTableState((prevTablesState) => {
				const updatedState = cloneDeep(prevTablesState);
				updatedState[tableId] = newStateValue;
				return updatedState;
			});
		},
		[]
	);

	const getTableStateById = useCallback(
		(tableId: string): ITableStateValue<CustomConfigTableType> => {
			const currentState = cloneDeep(tablesState);
			return currentState[tableId] || getDefaultState(tableId);
		},
		[tablesState]
	);

	const onTablePageOrPageSizeChange = useCallback(
		(tableId: string, pagination: ITablePagination): void => {
			const tableState = getTableStateById(tableId);
			const newState = {
				...tableState,
				paginationInfo: { ...pagination }
			};
			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	const onTableParamStatusChange = useCallback(
		(
			tableId: string,
			paramKey: keyof CustomConfigTableType,
			paramMethod: () => CustomConfigTableType[typeof paramKey]
		): void => {
			const tableState = getTableStateById(tableId);
			const newState = {
				...tableState,
				customParams: {
					...(tableState.customParams ?? {}),
					[paramKey]: paramMethod()
				} as CustomConfigTableType
			};
			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	const onTableSort = useCallback(
		(tableId: string, sort: TableSortOption[]): void => {
			const tableState = getTableStateById(tableId);

			const newState = { ...tableState, tableSort: sort };

			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	const onFilterChange = useCallback(
		(tableId: string, filters: IFilterOptions): void => {
			const tableState = getTableStateById(tableId);
			const paginationInfo = { ...tableState.paginationInfo, page: 1 };
			const newState = { ...tableState, filters, paginationInfo };

			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	useEffect(() => {
		localStorageApi.saveToLocalStorage(TABLES_STATE, tablesState);
	}, [tablesState]);

	const value = React.useMemo(
		() => ({
			tablesState,
			getTableStateById,
			updateTableState,
			onTablePageOrPageSizeChange,
			onTableParamStatusChange,
			onTableSort,
			onFilterChange
		}),
		[
			tablesState,
			getTableStateById,
			updateTableState,
			onTablePageOrPageSizeChange,
			onTableParamStatusChange,
			onTableSort,
			onFilterChange
		]
	);
	return <TableStateContext.Provider value={value}>{children}</TableStateContext.Provider>;
};
