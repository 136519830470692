// This file was copied from site scanner. Planning to move it to ui-common.
import { EvSelect, EvTextInput, TEXT_INPUT_TYPES } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import { IAzureFormFieldProps } from '../../types/AzureTypes';

const IssueFormField: FC<IAzureFormFieldProps> = ({
	field,
	onChange
}: IAzureFormFieldProps): JSX.Element => {
	const renderStringField = (): JSX.Element => {
		return <EvTextInput value={field.value} onChange={onChange} />;
	};

	const renderTextAreaField = (): JSX.Element => {
		return (
			<textarea
				id={field.referenceName}
				className="custom_input"
				defaultValue={field.value}
				onChange={(e) => onChange(e.target.value)}
			/>
		);
	};

	const renderNumberField = (): JSX.Element => {
		const formatInteger: boolean = field.type === 'integer';

		return (
			<EvTextInput
				className="number-input"
				value={field.value}
				type={TEXT_INPUT_TYPES.NUMBER}
				onChange={(value) => onChange((formatInteger && String(parseInt(value))) || value)}
			/>
		);
	};

	const renderDateField = (): JSX.Element => {
		return (
			<input
				id={field.referenceName}
				value={field.value}
				type="date"
				onChange={(e) => onChange(e.target.value)}
			/>
		);
	};

	const renderBooleanField = (): JSX.Element => {
		const options = [
			{ value: 'true', label: 'True' },
			{ value: 'false', label: 'False' }
		];
		const currentValue = field.value ? [{ value: field.value, label: field.value as string }] : [];
		return (
			<EvSelect
				id={field.referenceName}
				options={options}
				value={currentValue}
				onChange={(value) => onChange(value)}
				placeholder="None Selected"
			/>
		);
	};

	const renderSelectField = (): JSX.Element => {
		const options = field.allowedValues.map((value) => {
			return { value, label: value };
		});
		const currentValue = field.value ? [{ value: field.value, label: field.value as string }] : [];
		return (
			<EvSelect
				id={field.referenceName}
				options={options}
				value={currentValue}
				onChange={(value) => onChange(value)}
				isSearchable
				placeholder="None Selected"
				someItemsSelectedText={(count) => `${field.name} (${count})`}
				allSelectedPlaceholder="All items selected"
				withMultilineOptions={field.name === 'Linked Issues'}
			/>
		);
	};

	const renderField = (): JSX.Element => {
		switch (field.type) {
			case 'string':
				return renderStringField();
			case 'integer':
			case 'double':
				return renderNumberField();
			case 'boolean':
				return renderBooleanField();
			case 'select':
				return renderSelectField();
			case 'textarea':
				return renderTextAreaField();
			case 'dateTime':
				return renderDateField();
			default:
				return null;
		}
	};

	return (
		<div className="form-input">
			<div className="form-element" key={field.referenceName}>
				<label className="new-form-label" htmlFor={field.referenceName}>
					{field.name}
					{field.alwaysRequired ? <span className="require-sign">*</span> : ''}
				</label>
				<div className="form-input">{renderField()}</div>
			</div>
		</div>
	);
};

export default IssueFormField;
