import React, { Dispatch, SetStateAction } from 'react';
import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';
import DownChevronBlueIcon from '../../icons/DownChevronBlueIcon.svg';
import UpChevronBlueIcon from '../../icons/UpChevronBlueIcon.svg';
import './ShowMoreButton.scss';

interface ShowMoreButtonProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	collapseTitle?: string;
	expandTitle?: string;
}

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
	isOpen,
	setIsOpen,
	collapseTitle = 'Collapse Panel',
	expandTitle = 'Expand Panel'
}) => (
	<EvButton
		title={isOpen ? collapseTitle : expandTitle}
		type={BUTTON_TYPES.ICON}
		onClick={() => setIsOpen((isOpen) => !isOpen)}
		className="show-more-button"
	>
		{isOpen ? 'Show less' : 'Show more'}
		<EvIcon className="chevron-icon" icon={!isOpen ? UpChevronBlueIcon : DownChevronBlueIcon} />
	</EvButton>
);
