import { DropdownOptionType, IDropdownMenuOption } from '@evinced-private/ui-common';
import RoutesHelper from '../../../helpers/RoutesHelper';

type getEvincedAppsMenuOptionsHelperArgs = {
	hasMfaLicense: boolean;
	hasMsdkLicense: boolean;
	hasWebSdkLicense: boolean;
	hasSiteScannerLicense: boolean;
	hasWfaLicense: boolean;
};

export const getEvincedAppsDropdownMenuOptions = (
	licenses: getEvincedAppsMenuOptionsHelperArgs
): IDropdownMenuOption[] => {
	const {
		hasMfaLicense,
		hasMsdkLicense,
		hasWebSdkLicense,
		hasSiteScannerLicense,
		hasWfaLicense
	} = licenses;
	const options: IDropdownMenuOption[] = [];

	const isMFADashboard = window.location.href.includes(RoutesHelper.getMfaDashboardPath());
	const isWFADashboard = window.location.href.includes(RoutesHelper.getWfaDashboardPath());
	const isMobileSDK = window.location.href.includes(RoutesHelper.getMobileSdkDashboardPath());
	const isWebSDK = window.location.href.includes(RoutesHelper.getWebSdkDashboardPath());
	const isSiteScanner = window.location.href.includes(process.env.SITE_SCANNER_DASHBOARD_URL);
	const isProductHub = window.location.href.includes(process.env.PRODUCT_HUB_URL);

	const defaultProps = {
		type: DropdownOptionType.LINK,
		hasBottomBorder: false,
		openInNewTab: true
	};

	// TODO: refactor to loop through array of apps
	// each app will have a license flag and the rest of the props

	if (hasMfaLicense) {
		options.push({
			id: 'mfa-dashboard-url',
			url: RoutesHelper.getMfaDashboardPath(),
			title: 'Mobile Flow Analyzer',
			active: isMFADashboard,
			...defaultProps
		});
	}
	if (hasWfaLicense) {
		options.push({
			id: 'wfa-dashboard-url',
			url: RoutesHelper.getWfaDashboardPath(),
			title: 'Web Flow Analyzer',
			active: isWFADashboard,
			...defaultProps
		});
	}
	if (hasMsdkLicense) {
		options.push({
			id: 'mobile-sdk-dashboard-url',
			url: RoutesHelper.getMobileSdkDashboardPath(),
			title: 'Mobile SDKs',
			active: isMobileSDK,
			...defaultProps
		});
	}
	if (hasWebSdkLicense) {
		options.push({
			id: 'web-sdk-dashboard-url',
			url: RoutesHelper.getWebSdkDashboardPath(),
			title: 'Web SDKs',
			active: isWebSDK,
			...defaultProps
		});
	}
	if (hasSiteScannerLicense) {
		options.push({
			id: 'site-scanner-url',
			url: process.env.SITE_SCANNER_URL,
			title: 'Site Scanner',
			active: isSiteScanner,
			...defaultProps
		});
	}
	options.push({
		id: 'product-hub',
		url: process.env.PRODUCT_HUB_URL,
		title: 'Product Hub',
		active: isProductHub,
		...defaultProps
	});
	return options;
};
