import { formatDate } from 'src/helpers/DateFormatHelper';

const formatLabel = (label: string, value: string[] | string): string => {
	if (Array.isArray(value)) {
		return `${label} (${value?.length || 0})`;
	}
	return label;
};

const formatValue = (value: string[] | string, isDate?: boolean): string => {
	const formattedValue = Array.isArray(value) ? value.join(', ') : value;
	if (isDate) {
		return formatDate(formattedValue as string);
	}

	return formattedValue as string;
};

export default {
	formatLabel,
	formatValue
};
