import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { BarDatum, EvBarChart, EvChartTooltip, EvincedColors } from '@evinced-private/ui-common';
import pluralize from 'pluralize';
import { SeverityCountType } from '../../../../../../../types/wfa/SeverityCountType';
import UUIDHelper from '../../../../../../../helpers/UUIDHelper';
import EvChartTableLink from '../ev-chart-table-link/EvChartTableLink';
import { prepareDataForSeverityChart } from './SeveritiesChartHelper';
import './BreakdownBySeveritiesChart.scss';

const { severitiesPatterns } = EvincedColors;

interface IBreakdownBySeverityChartProps {
	severitiesCount: SeverityCountType[];
	onBarItemClick: (filterOptions: BarDatum) => void;
}

const BreakdownBySeverityChart: FC<IBreakdownBySeverityChartProps> = ({
	severitiesCount,
	onBarItemClick
}: IBreakdownBySeverityChartProps) => {
	const history = useHistory();
	const tooltipRenderer = (d): JSX.Element => {
		return <EvChartTooltip label={d.data.severity} value={pluralize('Issue', d.value, true)} />;
	};
	const customSrSummaryRenderer = (barData: BarDatum[]): JSX.Element => {
		return (
			<ul aria-label="Severities Breakdown Chart Summary">
				{barData.map(({ id, severity, count }) => {
					const typeText = severity && severity !== id ? severity : '';
					const text = `${typeText}: ${count} issues`;
					return <li key={UUIDHelper.generateUUID()}>{text}</li>;
				})}
			</ul>
		);
	};

	if (severitiesCount.length === 0) {
		return null;
	}

	// we're assuming the severities count list is already sorted by severity order
	const barData = prepareDataForSeverityChart(severitiesCount);
	const severityFieldFormatter = (cell: string, row): React.ReactNode => {
		const url = 'tbd';

		return (
			<EvChartTableLink
				value={cell}
				onClick={() => {
					history.push({
						...{
							pathname: url,
							state: {
								severity: row.severity,
								searchText: ''
							}
						}
					});
				}}
			/>
		);
	};

	return (
		<EvBarChart
			title="Breakdown By Issue Severity"
			data={barData}
			patterns={severitiesPatterns}
			className="breakdown-by-severity-chart"
			keys={['count']}
			indexBy="label"
			width="100%"
			height={400}
			customSrSummaryRenderer={() => customSrSummaryRenderer(barData)}
			onBarClick={(barData) =>
				onBarItemClick({
					severity: barData.data.severity,
					searchText: ''
				})
			}
			withLegend={false}
			isFocusable={true}
			tableProps={{
				dataType: 'Severity',
				columnsPattern: [
					{ name: 'Severity', field: 'label', formatter: severityFieldFormatter },
					{ name: 'Total Issues', field: 'count' }
				]
			}}
			barDesignProps={{
				margin: { top: 40, right: 0, bottom: 40, left: 40 },
				enableLabel: false,
				padding: 0.6,
				borderWidth: 2,
				borderColor: { from: 'color', modifiers: [['opacity', 0]] },
				tooltip: tooltipRenderer,
				animate: true,
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickPadding: 10,
					tickSize: 0
				},
				axisBottom: {
					tickSize: 0,
					tickPadding: 10,
					renderTick: undefined
				}
			}}
		/>
	);
};

export default BreakdownBySeverityChart;
