import React, { FC, useCallback, useEffect, useState } from 'react';
import { EvSpinner } from '@evinced-private/ui-common';
import { useHistory } from 'react-router';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { ProductType } from '../../interfaces/Tenant';
import LoadErrorMsgLayout from '../property/LoadErrorMsgLayout';
import ErrorLoadPageIcon from '../../components/icons/ErrorLoadPageIcon.svg';
import { ContactUsButton } from '../../components/contact-us/ContactUsButton';
import MFASessionsPage from '../mfa-dashboard/MfaSessionsPage';
import MSdkDashboardTestsPage from '../mobile-sdk-dashboard/MSdkDashboardSessionsPage';
import WebSdkTestsPage from '../web-sdk-dashboard/WebSdkTestsPage';
import WfaDashboardPage from '../wfa-dashboard/sessions-page/WfaSessionsPage';
import { TableStateProvider } from '../../providers/dashboard/TableStateProvider';
import { ACCESS_DENIED_MSG, ACCESS_DENIED_TITLE } from './errorMessages';
import Logger from '../../services/Logger';
import { getProductDashboardAccessPermission } from '../../services/CommonDashboardService';
import { useConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import './DashboardContainer.scss';

interface IDashboardContainerProps {
	product: ProductType;
}

const DashboardContainer: FC<IDashboardContainerProps> = ({ product }) => {
	const history = useHistory();
	const { tenant, getProductIdByType } = useUserTenant();
	const { getToggle, configuration } = useConfiguration();
	const productId: string = getProductIdByType(product);

	const [isDashboardEnabled, setIsDashboardEnabled] = useState<boolean>(null);

	const getDashboardComponent = (): JSX.Element => {
		switch (product) {
			case ProductType.MOBILE_FLOW_ANALYZER:
				return <MFASessionsPage />;
			case ProductType.MOBILE_SDK:
				return <MSdkDashboardTestsPage />;
			case ProductType.WEB_SDK:
				return <WebSdkTestsPage />;
			case ProductType.WEB_FLOW_ANALYZER:
				return <WfaDashboardPage />;
			default:
				return null;
		}
	};

	const homePageRedirection = useCallback((): void => {
		const isPlatformEnabledForMfa = getProductDashboardAccessPermission(
			tenant,
			getToggle,
			ProductType.MOBILE_FLOW_ANALYZER
		);
		if (isPlatformEnabledForMfa) {
			history.push(RoutesHelper.getMfaDashboardPath());
		} else {
			const isPlatformEnabledForMSdk = getProductDashboardAccessPermission(
				tenant,
				getToggle,
				ProductType.MOBILE_SDK
			);
			if (isPlatformEnabledForMSdk) {
				history.push(RoutesHelper.getMobileSdkDashboardPath());
			} else {
				const isPlatformEnabledForWfa = getProductDashboardAccessPermission(
					tenant,
					getToggle,
					ProductType.WEB_FLOW_ANALYZER
				);
				if (isPlatformEnabledForWfa) {
					history.push(RoutesHelper.getWfaDashboardPath());
				} else {
					history.push(RoutesHelper.getWebSdkDashboardPath());
				}
			}
		}
	}, [history, getToggle, tenant]);

	const initDashboard = useCallback((): void => {
		try {
			const isPlatformEnabledForCurrentProduct = getProductDashboardAccessPermission(
				tenant,
				getToggle,
				product
			);
			setIsDashboardEnabled(isPlatformEnabledForCurrentProduct);
		} catch (err) {
			Logger.error('Failed to get product features', err);
		}
	}, [tenant, getToggle, product]);

	useEffect(() => {
		if (!configuration) {
			// because permissions depend on configuration from the server, we need to wait for it
			return;
		}
		if (product === ProductType.HOME) {
			homePageRedirection();
		} else if (productId) {
			initDashboard();
		} else {
			setIsDashboardEnabled(false);
		}
	}, [productId, initDashboard, homePageRedirection, product, configuration]);

	if (isDashboardEnabled === null) {
		return <EvSpinner />;
	}

	return isDashboardEnabled ? (
		<TableStateProvider>{getDashboardComponent()}</TableStateProvider>
	) : (
		<LoadErrorMsgLayout
			mainTitle={ACCESS_DENIED_TITLE}
			secondaryMsg={ACCESS_DENIED_MSG}
			icon={ErrorLoadPageIcon}
			className="mfa-dashboard-access-denied-msg"
		>
			<ContactUsButton product={product} />
		</LoadErrorMsgLayout>
	);
};

export default DashboardContainer;
