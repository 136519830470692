/* eslint-disable no-mixed-spaces-and-tabs */
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { EvSpinner, PendoService } from '@evinced-private/ui-common';
import { isEqual } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Unauthorized403Page from '../../pages/unauthorized-403-page/Unauthorized403Page';
import ApiErrorHelper from '../../helpers/ApiErrorHelper';
import EnvironmentHelper from '../../helpers/EnvironmentHelper';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import RoutesHelper, { getProductTypeFromPath } from '../../helpers/RoutesHelper';
import AuthenticationService from '../../services/AuthenticationService';
import Logger from '../../services/Logger';
import loginRedirectionService from '../../services/LoginRedirectionService';
import tenantsService from '../../services/TenantsService';
import userInvitationsService from '../../services/UserInvitationService';
import FullPageError from '../errors/full-page-error/FullPageError';

const pendoApiKey = process.env.PENDO_API_KEY;

const getCurrentRelativeUrl = (): string => {
	let url = window.location.pathname;
	const params = window.location.search;
	const urlParams = new URLSearchParams(params);
	const searchParams = urlParams.toString();
	if (searchParams) {
		url += `?${searchParams}`;
	}
	return url;
};

const saveCurrentUrlForRedirection = async (): Promise<void> => {
	const redirectToUrl = getCurrentRelativeUrl();
	loginRedirectionService.setRedirectToPath(redirectToUrl);
};
/**
 *
 * @returns {hasTenant: boolean, hasAccessToProduct: boolean,
 *  hasPendingInvitations: boolean }
 */
const getUserStatus = async (): Promise<{
	hasTenant: boolean;
	hasAccessToProduct: boolean;
	hasPendingInvitations: boolean;
}> => {
	const userTenant = await tenantsService.getUserTenant();
	const hasAccessToProduct = userTenant
		? await tenantsService.hasAccessToProduct(productType, userTenant)
		: false;
	const invitaitonTokenFromUrl = InvitationTokenHelper.getInvitationTokenFromUrl();
	const pendingInvitations = await userInvitationsService.getAllUserPendingInvitations();

	return {
		hasTenant: !!userTenant,
		hasAccessToProduct,
		hasPendingInvitations: !!invitaitonTokenFromUrl || pendingInvitations.length > 0
	};
};

const initializePendo = (): void => {
	if (!EnvironmentHelper.isCI()) {
		PendoService.init(
			{
				visitorId: AuthenticationService.getEvincedUserId(),
				email: AuthenticationService.getUserEmail(),
				accountId: AuthenticationService.getUserEmailDomain()
			},
			pendoApiKey,
			Logger
		);
	}
};

const productType = getProductTypeFromPath();

function PrivateRoute({ component: RouteComponent, ...rest }): JSX.Element {
	const [isLoading, setLoading] = useState(true);
	const [userStatus, setUserStatus] = useState({
		hasTenant: false,
		hasAccessToProduct: false,
		hasPendingInvitations: false
	});
	const [error, setError] = useState(null);
	const { location } = rest;

	const checkIfUserHasTenant = useCallback(async () => {
		// short circuit - no need to check if there's a tenant on each route if we know they have
		if (userStatus.hasTenant && userStatus.hasAccessToProduct) {
			return;
		}
		setLoading(true);

		try {
			const updatedUserStatus = await getUserStatus();
			if (!isEqual(updatedUserStatus, userStatus)) {
				setUserStatus(updatedUserStatus);
			}
		} catch (error) {
			Logger.error('Error getting tenant', error);
			setError(
				`An error occurred while retrieving your account information. ${ApiErrorHelper.getErrorMessage(
					error
				)}`
			);
		} finally {
			setLoading(false);
		}
	}, [userStatus]);

	useEffect(() => {
		checkIfUserHasTenant();
	}, [checkIfUserHasTenant]);

	if (isLoading) {
		return <EvSpinner />;
	}
	if (!isLoading && !userStatus.hasAccessToProduct) {
		return <Unauthorized403Page />;
	}
	if (error) {
		return <FullPageError title="Product Loading Error" />;
	}
	initializePendo();
	return (
		<Route
			{...rest}
			location={location}
			render={(props) => {
				if (!AuthenticationService.isLoggedIn()) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location },
								search: props.location.search
							}}
						/>
					);
				}

				// handling account activation and product invitation
				const { pathname } = location;
				const { hasTenant, hasAccessToProduct } = userStatus;

				if (!hasTenant || !hasAccessToProduct) {
					let redirectTo = '';
					if (hasTenant && !hasAccessToProduct) {
						redirectTo = RoutesHelper.getMfaDashboardPath();
					}
					// no need for redirect if the user is already in that path
					if (pathname !== redirectTo) {
						return (
							<Redirect
								to={{
									pathname: redirectTo,
									state: { from: props.location },
									search: props.location.search
								}}
							/>
						);
					}
				}
				return <RouteComponent {...props} />;
			}}
		/>
	);
}

const Component = EnvironmentHelper.isCI()
	? PrivateRoute
	: withAuthenticationRequired(PrivateRoute, {
			onBeforeAuthentication: saveCurrentUrlForRedirection,
			onRedirecting: () => {
				return <EvSpinner />;
			}
	  });

export default Component;
