// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-sessions-header .page-heading {
  margin-bottom: 15px;
}
.wfa-sessions-header .page-heading.main-title .title {
  font-size: 22px;
}
.wfa-sessions-header .data-filters {
  padding: 15px 0;
  max-width: 1390px;
}
.wfa-sessions-header .data-filters.ev-filters > :not(:last-child) {
  margin-right: 10px;
}
.wfa-sessions-header .data-filters.ev-filters .ev-select__indicators .ev-select__indicator {
  padding-right: 10px;
}
.wfa-sessions-header .data-filters.ev-filters .ev-select__value-container {
  padding-left: 10px;
}
.wfa-sessions-header .data-filters .search-filter {
  width: 330px;
}`, "",{"version":3,"sources":["webpack://./src/pages/wfa-dashboard/sessions-page/sections/header/WfaSessionsHeader.scss","webpack://./src/styles/evFilters.scss"],"names":[],"mappings":"AAIC;EACC,mBAAA;AAHF;AAMG;EACC,eAAA;AAJJ;AASC;ECbA,eAAA;EACA,iBAAA;ADOD;ACFE;EACC,kBAAA;ADIH;ACFE;EACC,mBAAA;ADIH;ACFE;EACC,kBAAA;ADIH;AADE;EACC,YAAA;AAGH","sourcesContent":["@import '../../../../../styles/variables.scss';\n@import '../../../../../styles/evFilters.scss';\n\n.wfa-sessions-header {\n\t.page-heading {\n\t\tmargin-bottom: 15px;\n\n\t\t&.main-title {\n\t\t\t.title {\n\t\t\t\tfont-size: 22px;\n\t\t\t}\n\t\t}\n\t}\n\n\t.data-filters {\n\t\t@include evFilters();\n\n\t\t.search-filter {\n\t\t\twidth: 330px;\n\t\t}\n\t}\n}\n","@mixin evFilters() {\n\tpadding: 15px 0;\n\tmax-width: 1390px;\n\n\t// for platform, in figma, margins are 10px, not 15px.\n\t// this helps with having shorter dropdowns and fitting everything in 1 line\n\t&.ev-filters {\n\t\t& > :not(:last-child) {\n\t\t\tmargin-right: 10px;\n\t\t}\n\t\t.ev-select__indicators .ev-select__indicator {\n\t\t\tpadding-right: 10px;\n\t\t}\n\t\t.ev-select__value-container {\n\t\t\tpadding-left: 10px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
