// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scan-data-filter {
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}
.scan-data-filter > *:not(:last-child) {
  margin-right: 15px;
}
.scan-data-filter .scan-data-filter-dropdown {
  max-width: 146px;
}
.scan-data-filter .scan-data-filter-dropdown.select_level {
  min-width: 210px;
}
.scan-data-filter .scan-data-filter-dropdown.select_level .ev-select-container {
  min-width: 210px;
}
.scan-data-filter .scan-data-filter-dropdown.select_types {
  min-width: 180px;
}
.scan-data-filter .scan-data-filter-dropdown.select_types .ev-select-container {
  min-width: 180px;
}
.scan-data-filter .scan-data-filter-dropdown.select_version {
  min-width: 160px;
}
.scan-data-filter .scan-data-filter-dropdown.select_version .ev-select-container {
  min-width: 160px;
}
.scan-data-filter .scan-data-filter-dropdown .ev-select-container {
  min-width: 146px;
}
.scan-data-filter .scan-data-filter-dropdown .ev-select-container .ev-select__menu {
  width: unset;
  min-width: 100%;
  max-width: 250px;
}
.scan-data-filter .scan-data-filter-dropdown .ev-select-container .ev-select__menu .ev-select__option .ev-checkbox {
  white-space: nowrap;
}
.scan-data-filter .scan-data-filter-dropdown .ev-select-container.with-multiline-options .ev-select__menu {
  max-width: 400px;
}
.scan-data-filter .scan-data-filter-dropdown .ev-select-container.with-multiline-options .ev-select__menu .ev-select__option .ev-checkbox {
  white-space: unset;
}
.scan-data-filter.msdk-tests-table .scan-data-filter-dropdown {
  max-width: 264px;
}
.scan-data-filter.msdk-tests-table .scan-data-filter-dropdown .ev-select-container {
  width: 264px;
}`, "",{"version":3,"sources":["webpack://./src/components/data-filter/DataFilter.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACD;AACC;EACC,kBAAA;AACF;AAEC;EACC,gBAAA;AAAF;AAEE;EACC,gBAAA;AAAH;AAEG;EACC,gBAAA;AAAJ;AAIE;EACC,gBAAA;AAFH;AAIG;EACC,gBAAA;AAFJ;AAME;EACC,gBAAA;AAJH;AAMG;EACC,gBAAA;AAJJ;AAQE;EACC,gBAAA;AANH;AAQG;EACC,YAAA;EACA,eAAA;EACA,gBAAA;AANJ;AASK;EACC,mBAAA;AAPN;AAaI;EACC,gBAAA;AAXL;AAaM;EACC,kBAAA;AAXP;AAoBE;EACC,gBAAA;AAlBH;AAmBG;EACC,YAAA;AAjBJ","sourcesContent":[".scan-data-filter {\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-top: 20px;\n\tflex-wrap: wrap;\n\n\t& > *:not(:last-child) {\n\t\tmargin-right: 15px;\n\t}\n\n\t.scan-data-filter-dropdown {\n\t\tmax-width: 146px;\n\n\t\t&.select_level {\n\t\t\tmin-width: 210px;\n\n\t\t\t.ev-select-container {\n\t\t\t\tmin-width: 210px;\n\t\t\t}\n\t\t}\n\n\t\t&.select_types {\n\t\t\tmin-width: 180px;\n\n\t\t\t.ev-select-container {\n\t\t\t\tmin-width: 180px;\n\t\t\t}\n\t\t}\n\n\t\t&.select_version {\n\t\t\tmin-width: 160px;\n\n\t\t\t.ev-select-container {\n\t\t\t\tmin-width: 160px;\n\t\t\t}\n\t\t}\n\n\t\t.ev-select-container {\n\t\t\tmin-width: 146px;\n\n\t\t\t.ev-select__menu {\n\t\t\t\twidth: unset;\n\t\t\t\tmin-width: 100%;\n\t\t\t\tmax-width: 250px;\n\n\t\t\t\t.ev-select__option {\n\t\t\t\t\t.ev-checkbox {\n\t\t\t\t\t\twhite-space: nowrap;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&.with-multiline-options {\n\t\t\t\t.ev-select__menu {\n\t\t\t\t\tmax-width: 400px;\n\t\t\t\t\t.ev-select__option {\n\t\t\t\t\t\t.ev-checkbox {\n\t\t\t\t\t\t\twhite-space: unset;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n\n\t&.msdk-tests-table {\n\t\t.scan-data-filter-dropdown {\n\t\t\tmax-width: 264px;\n\t\t\t.ev-select-container {\n\t\t\t\twidth: 264px;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
