import { gqlClient } from '../../api/GraphQLRequestApi';
import { ProductType } from '../../interfaces/Tenant';
import Logger from '../Logger';
import { IssueDetailsQuery } from './queries/issue-details/IssueDetails.generated';
import { buildIssueDetailsVariables } from './queries/issue-details/helpers/buildVariables';
import { transformIssueDetailsResults } from './queries/issue-details/helpers/transformResults';
import { IssueDetails } from './queries/issue-details/IssueDetails.gql';
import { PlatformIssueDetails } from '../../types/IssueDetailsType';

export const getPlatformIssueDetails = async (
	issueId: string,
	runId: string,
	product: ProductType
): Promise<PlatformIssueDetails> => {
	try {
		const variables = buildIssueDetailsVariables(issueId, runId);

		const result = await gqlClient.request<IssueDetailsQuery>(IssueDetails, variables);

		const transformedResult = transformIssueDetailsResults(result, product);
		if (!transformedResult) {
			throw new Error(`Issue ${runId} > ${issueId} not found.`);
		}
		return transformedResult;
	} catch (e) {
		Logger.error('CommonIssueDetailsService -> getPlatformIssueDetails failed with an error:', e);
		throw e;
	}
};
