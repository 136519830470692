import React from 'react';
import LoadErrorMsgLayout from '../../property/LoadErrorMsgLayout';
import { WEB_SDK_ERROR_MSG, WEB_SDK_ERROR_TITLE } from '../../dashboards-container/errorMessages';
import ErrorLoadPageIcon from '../../../components/icons/ErrorLoadPageIcon.svg';
import { ContactUsButton } from '../../../components/contact-us/ContactUsButton';
import { ProductType } from '../../../interfaces/Tenant';
import './WebSdkError.scss';

interface WebSDKErrorProps {
	mainTitle?: string;
	secondaryMsg?: string;
	button?: React.ReactElement;
	icon?: unknown;
	hideButton?: boolean;
}
export const WebSdkError: React.FC<WebSDKErrorProps> = ({
	button,
	mainTitle = WEB_SDK_ERROR_TITLE,
	secondaryMsg = WEB_SDK_ERROR_MSG,
	icon = ErrorLoadPageIcon,
	hideButton
}) => {
	return (
		<LoadErrorMsgLayout
			mainTitle={mainTitle}
			secondaryMsg={secondaryMsg}
			icon={icon}
			className="web-sdk-dashboard-error-msg"
		>
			{!hideButton ? button || <ContactUsButton product={ProductType.WEB_SDK} /> : null}
		</LoadErrorMsgLayout>
	);
};
