import {
	MSDK_REPORT_ID_PARAM,
	MSDK_TEST_NAME_PARAM
} from '../pages/mobile-sdk-dashboard/msdk-dashboard-consts';
import { ProductType } from '../interfaces/Tenant';
import { TOKEN_QUERY_PARAM } from './InvitationTokenHelper';
import { SessionViews } from '../pages/wfa-dashboard/WfaDashboardConsts';

const productHubUrl = process.env.PRODUCT_HUB_URL;

interface WebSdkInfoPathParams {
	runId: string;
	runTime: string;
	issueId: string;
	testName?: string | null;
}

interface WfaInfoPathParams {
	runId: string;
	issueId: string;
}
export default class RoutesHelper {
	// common
	public static getHomepagePath(): string {
		return '/';
	}

	public static getLoginPath(): string {
		return '/login';
	}

	public static getLoginSuccessPath(): string {
		return '/login-success';
	}

	public static getSignupPath(): string {
		return '/signup';
	}

	public static getInvitationActivationPage(invitationToken: string): string {
		return `/invitation-activation${
			invitationToken ? `?${TOKEN_QUERY_PARAM}=${invitationToken}` : ''
		}`;
	}

	// MFA Dashboard
	public static getMfaDashboardPath(): string {
		return '/mfa';
	}

	public static getMfaSessionReportPathname(): string {
		return `${this.getMfaDashboardPath()}/report/`;
	}

	public static getMfaSessionReportPath({
		sessionId,
		sessionName
	}: {
		sessionId: string;
		sessionName: string;
	}): string {
		return `${this.getMfaSessionReportPathname()}?sessionId=${sessionId}&sessionName=${sessionName}`;
	}

	// Mobile SDK Dashboard
	public static getMobileSdkReportPath({
		reportId,
		testName,
		createdAt,
		userFiltersLabels
	}: {
		reportId: string;
		testName: string;
		createdAt: string;
		userFiltersLabels?: string[];
	}): string {
		return `${this.getMobileSdkTestReportPathname()}?${MSDK_REPORT_ID_PARAM}=${reportId}&${MSDK_TEST_NAME_PARAM}=${testName}&createdAt=${createdAt}&labels=${userFiltersLabels}`;
	}

	public static getMobileSdkDashboardPath(): string {
		return '/mobile-sdk';
	}

	public static getMobileSdkTestReportPathname(): string {
		return `${this.getMobileSdkDashboardPath()}/report/`;
	}

	// Web SDK

	public static getWebSdkDashboardPath(): string {
		return '/web-sdk';
	}

	public static getWebSdkDashboardTestPath(): string {
		return `${this.getWebSdkDashboardPath()}/test/`;
	}

	public static getWebSdkTestPath(
		runId: string,
		runTime: string,
		testName: string | null = null
	): string {
		return `${this.getWebSdkDashboardTestPath()}${runId}?runTime=${runTime}&testName=${testName}`;
	}

	public static getWebSdkIssueInfoPath({
		runId,
		issueId,
		runTime,
		testName = null
	}: WebSdkInfoPathParams): string {
		const mainTestPath = this.getWebSdkTestPath(runId, runTime, testName);
		const splitToPathAndParams = mainTestPath.split('?');
		return `${splitToPathAndParams[0]}/issue/${issueId}?${splitToPathAndParams[1]}`;
	}

	// WFA
	public static getWfaDashboardPath(): string {
		return '/wfa';
	}

	public static getWfaSessionBasePath(): string {
		return `${this.getWfaDashboardPath()}/session`;
	}

	private static getWfaSessionPathPrefix(sessionId: string): string {
		return `${this.getWfaSessionBasePath()}/${sessionId}`;
	}

	public static getWfaSessionPath(sessionId: string, viewId: SessionViews): string {
		return `${this.getWfaSessionPathPrefix(sessionId)}/${viewId ?? 'overview'}`;
	}

	public static getWfaIssueInfoPath({ runId, issueId }: WfaInfoPathParams): string {
		return `${this.getWfaSessionPathPrefix(runId)}/issue/${issueId}`;
	}

	// External URLs
	public static getUsersInvitationsHubPath(): string {
		return `${productHubUrl}/invitations`;
	}

	public static getUnauthorized403Page = (): string => {
		return '/unauthorized';
	};

	public static getJiraAuthenticationPath = (): string => {
		return '/jira-authentication';
	};

	public static getAzureAuthenticationPath(): string {
		return '/azure-authentication';
	}

	public static getConnectPath(): string {
		return '/connect';
	}
}

export const getProductTypeFromPath = (): ProductType => {
	const { pathname } = window.location;
	if (pathname.includes(RoutesHelper.getMfaDashboardPath())) {
		return ProductType.MOBILE_FLOW_ANALYZER;
	}
	if (pathname.includes(RoutesHelper.getWfaDashboardPath())) {
		return ProductType.WEB_FLOW_ANALYZER;
	}
	if (pathname.includes(RoutesHelper.getMobileSdkDashboardPath())) {
		return ProductType.MOBILE_SDK;
	}
	if (pathname.includes(RoutesHelper.getWebSdkDashboardPath())) {
		return ProductType.WEB_SDK;
	}

	return ProductType.HOME;
};
