import React, { FC } from 'react';
import {
	EvIssueTypesBySeverityBreakdownChart,
	EvSection,
	EvSpinner
} from '@evinced-private/ui-common';
import { WFA_SESSION_OVERVIEW_TAB_DAID } from 'src/pages/wfa-dashboard/WfaDashboardAutomationIds';
import SummaryPane from 'src/components/common/ev-summary-pane/EvSummaryPane';
import ComponentsRaceBarChart from './charts/race-bar/ComponentsRaceBarChart';
import BreakdownBySeverityChart from './charts/severities/BreakdownBySeverityChart';
import { useWfaTopSessionComponentsQuery } from '../../../hooks/useWfaSessionTopComponentsQuery';
import { useWfaSessionSeveritiesQuery } from '../../../hooks/useWfaSessionSeveritiesQuery';
import { useWfaSessionIssueTypesQuery } from '../../../hooks/useWfaSessionIssueTypesQuery';
import './WfaSessionOverview.scss';
import { useWfaSessionSummaryQuery } from '../../../hooks/useWfaSessionSummaryQuery';

interface IWfaSessionOverviewProps {
	sessionId: string;
}

export const WfaSessionOverview: FC<IWfaSessionOverviewProps> = ({ sessionId }) => {
	const topComponentsQuery = useWfaTopSessionComponentsQuery({
		sessionId
	});
	const severitiesQuery = useWfaSessionSeveritiesQuery({
		sessionId
	});
	const issuesTypesQuery = useWfaSessionIssueTypesQuery({
		sessionId
	});
	const sessionSummaryQuery = useWfaSessionSummaryQuery({
		sessionId
	});
	return (
		<EvSection className="wfa-session-overview" dataAutomationId={WFA_SESSION_OVERVIEW_TAB_DAID}>
			{!sessionSummaryQuery.isLoading ? (
				<SummaryPane
					sectionSrLabel="Scan summary"
					className="custom-summary-pane"
					items={[
						{ value: sessionSummaryQuery.data.totalIssues, label: 'Total Issues' },
						{ value: sessionSummaryQuery.data.totalComponents, label: 'Components' },
						{ value: sessionSummaryQuery.data.totalPages, label: 'Pages' }
					]}
				/>
			) : (
				<EvSpinner />
			)}
			<EvSection dataAutomationId="types-by-severities-chart-id">
				{issuesTypesQuery.data && !issuesTypesQuery.isLoading ? (
					<EvIssueTypesBySeverityBreakdownChart
						typesCountBySeverity={issuesTypesQuery.data}
						onBarItemClick={({ type, severity }) =>
							console.log(`Bar or table row clicked with type - ${type} , severity- ${severity}`)
						}
					/>
				) : (
					<EvSpinner />
				)}
			</EvSection>
			<div className="bottom-row-charts">
				<EvSection
					className="severities-chart-section bottom-chart-section"
					dataAutomationId="severities-chart"
					skipLinkId="severities-chart-id"
				>
					{severitiesQuery.data && !severitiesQuery.isLoading ? (
						<BreakdownBySeverityChart
							severitiesCount={severitiesQuery.data}
							onBarItemClick={() => null}
						/>
					) : (
						<EvSpinner />
					)}
				</EvSection>
				<EvSection
					className="race-bar-chart-section bottom-chart-section"
					dataAutomationId="race-bar-id"
				>
					{topComponentsQuery.data && !topComponentsQuery.isLoading ? (
						<ComponentsRaceBarChart sessionComponents={topComponentsQuery?.data} />
					) : (
						<EvSpinner />
					)}
				</EvSection>
			</div>
		</EvSection>
	);
};
