import { SORT_ORDER } from '@evinced-private/ui-common';
import {
	IssuesSortFields,
	IssuesSortOrder,
	IssuesTextSearchFields
} from '../../../../../types/graphql.generated';
import { IGetWebSdkTestIssuesParams } from '../../../WebSdkDashboardService.types';
import { IssuesQueryVariables } from '../Issues.generated';
import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';

const issuesSortFieldNamesMap: Record<string, IssuesSortFields> = {
	type: IssuesSortFields.TypeName,
	severity: IssuesSortFields.SeverityName,
	url: IssuesSortFields.Url,
	pageTitle: IssuesSortFields.PageTitle
};

const issuesSortOrderMap: Record<SORT_ORDER, IssuesSortOrder> = {
	[SORT_ORDER.ASC]: IssuesSortOrder.Ascending,
	[SORT_ORDER.DESC]: IssuesSortOrder.Descending
};

const issuesSearchFields = [
	IssuesTextSearchFields.TypeName,
	IssuesTextSearchFields.ElementsComponentId,
	IssuesTextSearchFields.SeverityName,
	IssuesTextSearchFields.Url,
	IssuesTextSearchFields.PageTitle
];

const RUN_ID_PARAM_NAME = 'runId';
const URL_FILTER_PARAM_NAME = 'urlFilter';
const TYPE_ID_FILTER_PARAM_NAME = 'typeIdFilter';
const SEVERITY_ID_FILTER_PARAM_NAME = 'severityIdFilter';
const LEVEL_FILTER_PARAM_NAME = 'levelFilter';
const PROPERTIES_FILTER_PARAM_NAME = 'propertiesFilter';

export const buildIssuesVariables = ({
	runId,
	filterOptions,
	sort,
	page = 0,
	pageSize = 10
}: IGetWebSdkTestIssuesParams): IssuesQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<IssuesQueryVariables>({
		searchFields: issuesSearchFields,
		sortFieldsMap: issuesSortFieldNamesMap,
		sortOrderMap: issuesSortOrderMap
	});

	const variables = variablesBuilder
		.addPaginationParams(page, pageSize)
		.addSortParam(sort)
		.addTextSearchParam(filterOptions?.searchValue)
		.addConditionParam(RUN_ID_PARAM_NAME, runId)
		.addFilterParam(URL_FILTER_PARAM_NAME, filterOptions?.urls)
		.addFilterParam(TYPE_ID_FILTER_PARAM_NAME, filterOptions?.types)
		.addFilterParam(SEVERITY_ID_FILTER_PARAM_NAME, filterOptions?.severities)
		.addFilterParam(LEVEL_FILTER_PARAM_NAME, filterOptions?.levels)
		.addFilterParam(PROPERTIES_FILTER_PARAM_NAME, filterOptions?.properties)
		.getVariables();

	return variables;
};
