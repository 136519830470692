/* eslint-disable camelcase */
import { OptionType } from '@evinced-private/ui-common';
import {
	PagesTextSearchFields,
	Wfa_Session_RunsTextSearchFields
} from 'src/types/graphql.generated';
import { WfaUsageDataQueryVariables } from '../WfaUsageData.generated';
import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';

const DAYS_AMOUNT_FILTER_PARAM_KEY = 'daysAmountFilter';
const DAYS_AMOUNT_X2_FILTER_PARAM_KEY = 'daysAmountx2Filter';

const sessionsSearchFields = [Wfa_Session_RunsTextSearchFields.SessionName];

export const buildWfaUsageDataVariables = (
	testPeriod: OptionType,
	searchValue?: string
): WfaUsageDataQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<WfaUsageDataQueryVariables>({
		searchFields: sessionsSearchFields
	});

	variablesBuilder.addTextSearchParam(searchValue ?? '');

	if (testPeriod?.value && testPeriod.value !== 'all' && typeof testPeriod.value === 'number') {
		variablesBuilder
			.addConditionParam(DAYS_AMOUNT_FILTER_PARAM_KEY, testPeriod.value)
			.addConditionParam(DAYS_AMOUNT_X2_FILTER_PARAM_KEY, 2 * testPeriod.value);
	}

	const variables = variablesBuilder.getVariables();

	return variables.textSearch
		? {
				...variables,
				textSearchPages: {
					...variables.textSearch,
					searchFields: [PagesTextSearchFields.SessionName]
				}
			}
		: variables;
};
