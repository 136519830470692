const middleEllipsis = (textInput: string, maxChars = 30): string => {
	const text = textInput.charAt(0) === '/' ? textInput.slice(1) : textInput;
	// No slashes and the string is long - Regular ellipsis
	if (!text.includes('/') && text.length > maxChars) {
		return `${text.slice(0, maxChars)}...`;
	}

	// No slashes and the string is short - The same string
	if (!text.includes('/') && text.length <= maxChars) {
		return text;
	}

	// Slashes and more than maxChars - Middle ellipsis
	if (text.includes('/') && text.length > maxChars) {
		const parts = text.split('/');
		const firstPart = parts[0];
		const lastPart = parts[parts.length - 1];

		// Calculate the remaining space for each part after the ellipsis
		const remainingSpace = maxChars - 5; // 5 for the "/.../"

		// Check if each part should be truncated
		const truncatedFirstPart =
			firstPart.length > remainingSpace ? `${firstPart.slice(0, remainingSpace)}...` : firstPart;
		const truncatedLastPart =
			lastPart.length > remainingSpace ? `...${lastPart.slice(-remainingSpace)}` : lastPart;

		return `${truncatedFirstPart}/.../${truncatedLastPart}`;
	}

	// Slashes and less than maxChars - No ellipsis
	return text;
};
export { middleEllipsis };
