import { uniqBy, upperFirst } from 'lodash-es';
import { OptionType } from '@evinced-private/ui-common';
import { TestLabelsQuery } from '../TestLabels.generated';
import { returnNonNull } from '../../../../../helpers/ArraysHelper';
import {
	convertCustomFieldToFilterOption,
	normalizeCustomField
} from '../../../../../helpers/GQLRequestsHelper/QueryResultsTransformHelper';

export const ENVIRONMENT_FILTER_NAME = 'Environment';
export const FLOW_FILTER_NAME = 'Flow';

const formatFullLabel = (prefix: string, value: string): string =>
	`${prefix}: ${upperFirst(value)}`;

export const transformTestLabelsResults = (rawData: TestLabelsQuery): OptionType[] => {
	const labelsResult = rawData.wsdk_test_labels?.[0];

	const customLabelFilters: OptionType[] =
		labelsResult?.custom_fields
			?.map(normalizeCustomField)
			.filter(returnNonNull)
			.map(convertCustomFieldToFilterOption) ?? [];

	const environmentFilters: OptionType[] =
		labelsResult?.environments
			?.filter((env) => env.length)
			.map((env) => ({
				label: formatFullLabel(ENVIRONMENT_FILTER_NAME, env),
				value: formatFullLabel(ENVIRONMENT_FILTER_NAME, env)
			})) ?? [];

	const flowFilters: OptionType[] =
		labelsResult?.flows
			?.filter((flow) => flow.length)
			.map((flow) => ({
				label: formatFullLabel(FLOW_FILTER_NAME, flow),
				value: formatFullLabel(FLOW_FILTER_NAME, flow)
			})) ?? [];

	return uniqBy([...environmentFilters, ...flowFilters, ...customLabelFilters], 'value');
};
