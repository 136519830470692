import { OptionType } from '@evinced-private/ui-common';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SummaryItem } from 'src/interfaces/SummaryItem';
import { TESTS_REFETCH_INTERVAL } from 'src/pages/web-sdk-dashboard/web-sdk-consts';
import { WFA_USAGE_DATA } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaUsageData } from 'src/services/wfa-dashboard/WfaDashboardService';

export const useWfaUsageDataQuery = (
	sessionsPeriod: OptionType[],
	searchValue: string
): UseQueryResult<SummaryItem[]> => {
	const query = useQuery(
		[WFA_USAGE_DATA, sessionsPeriod, searchValue],
		async (): Promise<SummaryItem[]> => getWfaUsageData(sessionsPeriod?.[0], searchValue),
		{
			refetchInterval: TESTS_REFETCH_INTERVAL
		}
	);

	return query;
};
