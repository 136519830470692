import ServerApi from 'src/api/ServerApi';
import {
	IGetHTMLReportArgs,
	IGetMfaSessionsArgs,
	IGetMfaSessionsResponse,
	IMfaEndpointResponse
} from 'src/pages/mfa-dashboard/mfa-types';
import API_URL_PATHS from '../../consts/ApiUrlPaths';

const getMfaSessions = async ({
	tenantId,
	productId
}: IGetMfaSessionsArgs): Promise<IGetMfaSessionsResponse[]> => {
	const res: IGetMfaSessionsResponse[] = await ServerApi.apiCall(
		'GET',
		`tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions`
	);
	return res;
};

const getHTMLReport = async ({
	tenantId,
	productId,
	sessionId
}: IGetHTMLReportArgs): Promise<string> => {
	const res: IMfaEndpointResponse = await ServerApi.apiCall(
		'GET',
		`/tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions/${sessionId}/html-report`
	);
	return res.link;
};

const deleteSessionById = async (
	tenantId: string,
	productId: string,
	sessionId: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const res = await ServerApi.apiCall(
		'DELETE',
		`/tenants/${tenantId}/products-export/${productId}/sessions/${sessionId}`
	);
	return res;
};

export default {
	getMfaSessions,
	getHTMLReport,
	deleteSessionById
};
