import { IWfaSessionDetails } from '../../../WfaDashboardService.types';
import { WfaSessionDetailsQuery } from '../WfaSessionDetails.generated';

export const transformSessionDetailsResult = (
	rawResults: WfaSessionDetailsQuery
): IWfaSessionDetails => {
	const session = rawResults?.wfa_session_runs?.[0];
	return {
		sessionId: session?.run_id,
		name: session?.session_name,
		runTime: session?.run_time,
		lastModifiedTime: session?.update_time,
		domain: '', // todo session?.domain,
		createdBy: '', // todo session?.created_by,
		detectionType: '', // todo implemented Detectiontype
		focusArea: 'Full page' // todo implemented rootSelector
	};
};
