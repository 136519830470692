/* eslint-disable camelcase */
import { SORT_ORDER } from '@evinced-private/ui-common';
import { WfaSessionsQueryVariables } from '../WfaSessions.generated';
import {
	Wfa_Session_RunsSortOrder,
	Wfa_Session_RunsSortFields,
	Wfa_Session_RunsTextSearchFields
} from '../../../../../types/graphql.generated';
import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';
import { ITableQueryParams } from '../../../../../types/TableQueryTypes';

const DAYS_AMOUNT_FILTER_PARAM_KEY = 'daysAmountFilter';

const sessionsTextSearchFields: Wfa_Session_RunsTextSearchFields[] = [
	Wfa_Session_RunsTextSearchFields.SessionName,
	Wfa_Session_RunsTextSearchFields.RunTime,
	Wfa_Session_RunsTextSearchFields.CriticalIssues,
	Wfa_Session_RunsTextSearchFields.TotalIssues
];

const sessionsSortFieldNamesMap: Record<string, Wfa_Session_RunsSortFields> = {
	sessionName: Wfa_Session_RunsSortFields.SessionName,
	runTime: Wfa_Session_RunsSortFields.RunTime,
	criticalIssues: Wfa_Session_RunsSortFields.CriticalIssues,
	totalIssues: Wfa_Session_RunsSortFields.TotalIssues
};

const sessionsSortOrderMap: Record<SORT_ORDER, Wfa_Session_RunsSortOrder> = {
	[SORT_ORDER.ASC]: Wfa_Session_RunsSortOrder.Ascending,
	[SORT_ORDER.DESC]: Wfa_Session_RunsSortOrder.Descending
};

export const buildSessionsQueryVariables = ({
	pageSize,
	page,
	sort,
	filterOptions
}: ITableQueryParams): WfaSessionsQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<WfaSessionsQueryVariables>({
		sortFieldsMap: sessionsSortFieldNamesMap,
		sortOrderMap: sessionsSortOrderMap,
		searchFields: sessionsTextSearchFields
	});

	variablesBuilder
		.addPaginationParams(page, pageSize)
		.addSortParam(sort)
		.addTextSearchParam(filterOptions?.searchValue ?? '');

         const selectedPeriod = filterOptions?.sessionsPeriod?.[0];
	if (selectedPeriod && selectedPeriod.value !== 'all') {
		variablesBuilder.addFilterParam(DAYS_AMOUNT_FILTER_PARAM_KEY, filterOptions?.sessionsPeriod);
	}

	return variablesBuilder.getVariables();
};
