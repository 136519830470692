// This file was copied from site scanner. Planning to move it to ui-common.
import { Report } from '../../../types/ReportModel';

function buildIssueDesc(issue): string {
	let desc = '';
	desc += `*Url:* ${issue.pageUrl} \n\n`;
	desc += issue.type && issue.type.name ? `*Type:* ${issue.type.name} \n\n` : '';
	desc += issue.summary ? `*Summary:* ${issue.summary} \n\n` : '';
	desc += issue.description ? `${issue.description} \n\n` : '';
	desc += issue.note && typeof issue.note === 'string' ? `*Notes:*\n ${issue.note} \n\n` : '';
	if (issue.elements && issue.elements.length > 1) {
		desc += 'h3. Elements: \n\n';
	}
	issue.elements.forEach((element, i) => {
		desc += `*Selector ${i > 0 ? i + 1 : ''}:*\n{noformat}\n ${element.selector}\n{noformat} \n\n`;
		desc += `*HTML Snippet:*\n{code:html} ${element.domSnippet}\n{code} \n\n`;
	});
	desc += issue.screenshotUrl ? 'See attached screenshot. \n\n' : '';
	desc +=
		issue.tags?.length > 0
			? `*WCAG Violations:* ${issue.tags
					.filter((tag) => tag.description && tag.link)
					.map((tag) => `\n\n * [${tag.description}|${tag.link}]`)}`
			: '';
	return desc;
}

function formatIssueForExport(issue, note): Report {
	issue.note = note;
	issue.description = buildIssueDesc(issue);
	return issue;
}
export default {
	formatIssueForExport
};
