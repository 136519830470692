import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
	EVINCED_SEVERITIES,
	EvincedColors,
	EvRaceBar,
	EvSection
} from '@evinced-private/ui-common';
import { SessionComponentType } from '../../../../../../../types/wfa/SessionComponentType';
import RaceBarChartHelper from './RaceBarChartHelper';
import EvChartTableLink from '../ev-chart-table-link/EvChartTableLink';
import NotGroupedComponent from '../../../../../../../consts/NotGroupedComponent';
import './ComponentsRaceBarChart.scss';

interface IComponentsRaceBarChartProps {
	sessionComponents: SessionComponentType[];
}
const ComponentsRaceBarChart: FC<IComponentsRaceBarChartProps> = ({
	sessionComponents
}: IComponentsRaceBarChartProps) => {
	const history = useHistory();
	const highestSeverity = 'CRITICAL'; // todo: get the highest severity from the server

	const { barData, totalPercentageCovered } =
		RaceBarChartHelper.prepareDataForChart(sessionComponents);

	const componentIdFieldFormatter = (componentId: string): React.ReactNode => {
		const url = 'component-url-should-be-here';

		return (
			<EvChartTableLink
				value={componentId}
				ariaLabel={
					componentId === NotGroupedComponent.DISPLAY_NAME
						? NotGroupedComponent.DISPLAY_NAME
						: `Component ID ${componentId}`
				}
				onClick={() => {
					history.push({
						...{
							pathname: url,
							state: {
								// this is used for passing data to the next page
								severity: highestSeverity,
								searchText: componentId
							}
						}
					});
				}}
			/>
		);
	};

	return (
		<EvSection className="components-race-bar-chart">
			<EvRaceBar
				className="components-race-bar-chart-inner"
				title="Breakdown by Component"
				description={`${barData.length} components cover ${totalPercentageCovered}% of ${EVINCED_SEVERITIES[highestSeverity]} issues`}
				data={barData}
				itemTypeLabel="Component"
				maxHeight={345}
				itemTooltipPercentDescription={`of ${highestSeverity} issues`}
				color={EvincedColors.newSeverityColors.CRITICAL}
				headingLevel={2}
				onBarClick={(barItem) => {
					history.push({
						pathname: barItem.url,
						state: {
							searchText: barItem.id,
							severity: highestSeverity,
							shouldResetFilters: true
						}
					});
				}}
				tableProps={{
					columnsPattern: [
						{ name: 'Component ID', field: 'id', formatter: componentIdFieldFormatter },
						{ name: 'Issues Percentage', field: 'percent' }
					]
				}}
			/>
		</EvSection>
	);
};

export default ComponentsRaceBarChart;
