import { TGetToggle } from '../providers/configurationProvider/ConfigurationProvider';
import { ITenant, ProductType } from '../interfaces/Tenant';
import { DevelopmentToggles } from './TogglesService';

const canViewDashboards = (getToggle: TGetToggle): boolean =>
	getToggle(DevelopmentToggles.CAN_VIEW_DASHBOARDS);

const canAccessProductDashboard = (getToggle: TGetToggle, product: ProductType): boolean => {
	switch (product) {
		case ProductType.MOBILE_FLOW_ANALYZER:
			return getToggle(DevelopmentToggles.CAN_ACCESS_MFA_DASHBOARD);
		case ProductType.MOBILE_SDK:
			return getToggle(DevelopmentToggles.CAN_ACCESS_MOBILE_SDK_DASHBOARD);
		case ProductType.WEB_SDK:
			return getToggle(DevelopmentToggles.CAN_ACCESS_WEB_SDK_DASHBOARD);
		case ProductType.WEB_FLOW_ANALYZER:
			return getToggle(DevelopmentToggles.CAN_ACCESS_WFA_DASHBOARD);
		default:
			return false;
	}
};

const canAccessDashboard = (getToggle, productType: ProductType): boolean =>
	canViewDashboards(getToggle) || canAccessProductDashboard(getToggle, productType);

export const hasLicenseForProduct = (tenant: ITenant, productType: string): boolean => {
	const product = tenant?.products?.find((prod) => prod.type === productType);
	if (!product) {
		return false;
	}

	// If no expirationTime it means that there is unlimited license for this product
	return (
		!product?.expirationTime ||
		(product?.expirationTime && new Date(product.expirationTime) > new Date())
	);
};

export const getProductDashboardAccessPermission = (
	tenant: ITenant,
	getToggle: TGetToggle,
	productType: ProductType
): boolean => {
	return hasLicenseForProduct(tenant, productType) && canAccessDashboard(getToggle, productType);
};
