// This file was copied from site scanner. Planned to move it to ui-common
import React, { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { EvErrorCard } from '@evinced-private/ui-common';
import { WEB_SDK_ERROR_MSG } from 'src/pages/dashboards-container/errorMessages';
import TogglesService, { DevelopmentToggles } from 'src/services/TogglesService';
import PlatformPopup from '../platform-popup/PlatformPopup';
import WebSdkDetailsBlock from '../../web-sdk/WebSdkDetailsBlock';
import { ProductType } from '../../../interfaces/Tenant';
import { WfaDetailsBlock } from '../../wfa/WfaDetailsBlock';
import './DetailsPopup.scss';

interface IDetailsPopupProps {
	product: ProductType;
	runId: string;
	issueId: string;
	runTime: string;
	testName?: string;
	issueType?: string;
	onClose?: () => void;
}

const enableAzureIntegration = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_AZURE_INTEGRATION
);

const DetailsPopup: FC<IDetailsPopupProps> = ({
	product,
	runId,
	issueId,
	onClose,
	runTime,
	testName,
	issueType = ''
}: IDetailsPopupProps) => {
	const [error, setError] = useState(null);
	const classes: string = classNames(error ? 'error-popup' : 'details-popup', {
		'azure-popup': enableAzureIntegration
	});

	const renderDetailsBlock = useMemo((): JSX.Element => {
		if (product === ProductType.WEB_SDK) {
			return (
				<WebSdkDetailsBlock
					runId={runId}
					issueId={issueId}
					runTime={runTime}
					testName={testName}
					setError={setError}
				/>
			);
		}
		return <WfaDetailsBlock runId={runId} issueId={issueId} />;
	}, [runId, issueId, runTime, testName, product]);

	return (
		<div>
			<PlatformPopup
				isOpen
				title={error ? 'Error' : issueType}
				className={classes}
				isBgTransparent
				isCentered={!!error}
				isControlled
				onClose={() => {
					setError?.(null);
					onClose?.();
				}}
				popupWidth={1050}
			>
				{error ? (
					<EvErrorCard title="Error" contactUsMessage={WEB_SDK_ERROR_MSG} />
				) : (
					renderDetailsBlock
				)}
			</PlatformPopup>
		</div>
	);
};

export default DetailsPopup;
