const calcPercentage = (count: number, totalCount: number, shouldRoundResult = true): number => {
	const decimal = count / totalCount;
	const percent = decimal * 100;
	if (shouldRoundResult) {
		return Math.round(percent);
	}
	return percent;
};

const calcPercentageDifference = (currentVal: number, prevVal: number): number => {
	if (currentVal === undefined || !prevVal) {
		return undefined;
	}

	return Math.round(((currentVal - prevVal) / prevVal) * 100);
};

export default {
	calcPercentage,
	calcPercentageDifference
};
