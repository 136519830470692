// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-sessions-usage {
  margin: 30px 0;
}
.wfa-sessions-usage .base-summary-pane .summary-pane-item:last-child .summary-pane-content .label {
  width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/pages/wfa-dashboard/sessions-page/sections/usage/WfaSessionsUsage.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;AACD;AAII;EACC,YAAA;AAFL","sourcesContent":[".wfa-sessions-usage {\n\tmargin: 30px 0;\n\n\t.base-summary-pane {\n\t\t.summary-pane-item:last-child {\n\t\t\t.summary-pane-content {\n\t\t\t\t.label {\n\t\t\t\t\twidth: 160px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
