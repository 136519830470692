import React from 'react';
import classNames from 'classnames';
import './HorizontalDivider.scss';

interface HorizontalDividerProps {
	className?: string;
	isDashed?: boolean;
}

export const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
	className,
	isDashed = true
}) => <div className={classNames('horizontal-divider', className, { dashed: isDashed })} />;
