import { EvLink, EvTitle, HeadingLevelRange } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import { IIssueTag } from '../../../types/IssueDetailsType';

interface IWcagTagsLinksBlockProps {
	subheadingsLevel: HeadingLevelRange;
	tags: IIssueTag[];
}

export const WcagTagsLinksBlock: FC<IWcagTagsLinksBlockProps> = ({ subheadingsLevel, tags }) => {
	if (!tags?.length) {
		return null;
	}
	return (
		<div className="wcag-part">
			<div className="part">
				<EvTitle
					titleText="WCAG Violations"
					className="subheading"
					headingLevel={subheadingsLevel}
				/>
			</div>
			<ul className="wcag-links">
				{tags
					.filter((tag) => tag.description && tag.link)
					.map((tag) => {
						return (
							<li className="ev-how-to-fix-option" key={tag.id}>
								<EvLink
									url={tag.link}
									linkText={tag.description}
									showIcon={false}
									openInNewTab={true}
								/>
							</li>
						);
					})}
			</ul>
		</div>
	);
};
