/* eslint-disable camelcase */
import { IssueTypesCount, TypesCountBySeverity } from '@evinced-private/ui-common';
import { groupBy, orderBy } from 'lodash-es';
import { SessionIssueTypesQuery } from '../SessionIssueTypes.generated';
import { EVINCED_ISSUE_SEVERITY_LIST } from './EvincedSeveritiesHelper';
import IdFormatterHelper from '../../../../../helpers/IdFormatterHelper';

export const transformSessionIssueTypesBreakdownResult = (
	rawResults: SessionIssueTypesQuery
): TypesCountBySeverity[] => {
	const allTypes = rawResults?.wfa_session_type_runs || [];
	const bySeverity = groupBy(allTypes, (t) => t.severity_id);
	const returnedServerIds = Object.keys(bySeverity);
	return (
		EVINCED_ISSUE_SEVERITY_LIST
			// keep only severities that has issue
			.filter((severityObj) => returnedServerIds.includes(severityObj.id))
			// map into chart like data structure
			.map((severityObj): TypesCountBySeverity => {
				const currentSeverityTypes = bySeverity[severityObj.id];
				if (!currentSeverityTypes) {
					return null;
				}
				const issueTypesCount: IssueTypesCount[] = orderBy(
					currentSeverityTypes,
					(t) => t.total_issues,
					'desc'
				).map((type) => {
					const { severity_name, type_name, total_issues } = type;
					return {
						// need to use formatTextToId so id will be without spaces
						id: IdFormatterHelper.formatTextToId(`${severity_name}-${type_name}`),
						typeID: type_name,
						typeLabel: type_name,
						severityId: severityObj.key,
						severityLabel: severity_name,
						count: total_issues
					};
				});
				return {
					severity: issueTypesCount?.[0]?.severityLabel,
					issueTypesCount
				};
			})
	);
};
