import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WFA_SESSION_SEVERITIES } from 'src/providers/reactQueryProvider/QueryKeys';
import { getSessionSeverities } from 'src/services/wfa-dashboard/WfaDashboardService';
import { SeverityCountType } from '../../../types/wfa/SeverityCountType';

interface IUseWfaSessionSeveritiesQuery {
	sessionId: string;
}

export const useWfaSessionSeveritiesQuery = ({
	sessionId
}: IUseWfaSessionSeveritiesQuery): UseQueryResult<SeverityCountType[]> => {
	const componentsQuery = useQuery(
		[WFA_SESSION_SEVERITIES, sessionId],
		async (): Promise<SeverityCountType[]> =>
			getSessionSeverities({
				sessionId
			}),
		{
			keepPreviousData: true
		}
	);

	return componentsQuery;
};
