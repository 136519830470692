import { WEB_SDK_TEST_TABLE_ID } from '../../pages/dashboards-container/dashboard_consts';

const SHOW_RECENT_TEST_DEFAULT = false;
const SHOW_TEST_PATH_DEFAULT = false;

export const showRecentTestKey = 'showRecentTest';
export const showTestPathKey = 'showTestPath';

type WebSdkTableCustomConfig = {
	[showRecentTestKey]: boolean;
	[showTestPathKey]: boolean;
};

export type CustomConfigTableType = WebSdkTableCustomConfig;

export const getDefualCustomState = (tableId: string): CustomConfigTableType => {
	if (tableId === WEB_SDK_TEST_TABLE_ID) {
		return {
			[showRecentTestKey]: SHOW_RECENT_TEST_DEFAULT,
			[showTestPathKey]: SHOW_TEST_PATH_DEFAULT
		};
	}
	return {} as CustomConfigTableType;
};
