// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ev-issue-form-popup .ev-popup-content,
.error-popup .ev-popup-content {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/jira-issue-creator/JiraIssueCreator.scss"],"names":[],"mappings":"AAGC;;EACC,WAAA;AADF","sourcesContent":["// This file was copied from site scanner. Planning to move it to ui-common.\n.ev-issue-form-popup,\n.error-popup {\n\t.ev-popup-content {\n\t\twidth: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
