import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';
import RoutesHelper from '../../helpers/RoutesHelper';
import { TableStateProvider } from '../../providers/dashboard/TableStateProvider';
import NotFound404Page from '../not-found-404-page/NotFound404Page';
import { ProductType } from '../../interfaces/Tenant';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { hasLicenseForProduct } from '../../services/CommonDashboardService';
import Unauthorized403Page from '../unauthorized-403-page/Unauthorized403Page';
import WebSdkTestIssuesPage from './WebSdkTestIssuesPage';
import WebSdkIssuePage from './WebSdkIssuePage';

const productType = ProductType.WEB_SDK;
const WebSdkRouter: FC = () => {
	const basePath = RoutesHelper.getWebSdkDashboardTestPath();
	const { tenant } = useUserTenant();
	const hasLicense = hasLicenseForProduct(tenant, productType);

	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={`${basePath}:runId`}
					render={() => {
						return hasLicense ? (
							<TableStateProvider>
								<WebSdkTestIssuesPage />
							</TableStateProvider>
						) : (
							<Unauthorized403Page />
						);
					}}
				/>
				<Route
					exact
					path={`${basePath}:runId/issue/:issueId`}
					render={() => {
						return hasLicense ? <WebSdkIssuePage /> : <Unauthorized403Page />;
					}}
				/>
				<Route render={() => <NotFound404Page />} />
			</Switch>
		</Router>
	);
};

export default WebSdkRouter;
