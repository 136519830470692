import { EvMarkdownViewer, EvTitle, HeadingLevelRange } from '@evinced-private/ui-common';
import React, { FC } from 'react';

interface IHowToFixBlockProps {
	subheadingsLevel: HeadingLevelRange;
	howToFix: string;
}

export const HowToFixBlock: FC<IHowToFixBlockProps> = ({ howToFix, subheadingsLevel }) => {
	return howToFix ? (
		<div className="how-to-fix">
			<div className="part">
				<EvTitle titleText="How to Fix" className="subheading" headingLevel={subheadingsLevel} />
			</div>
			<EvMarkdownViewer initialValue={howToFix} />
		</div>
	) : null;
};
