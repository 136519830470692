import { TITLE_MODES } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import ApiErrorHelper from '../../helpers/ApiErrorHelper';
import { IServerError } from '../../interfaces/ServerError';
import logger from '../../services/Logger';
import AllowedDomainsError from '../errors/AllowedDomainsError';
import SiteScannerPopup from '../common/platform-popup/PlatformPopup';
import ContactSupportMailLink, { SUPPORT_EMAIL } from '../contact-support/ContactSupportMailLink';
import './AlertPopup.scss';

export interface IAlertPopup {
	/**
	 * Error message to display
	 */
	errorMessage?: string;

	/**
	 * popup's title
	 */
	title?: string;

	/**
	 * Server error object
	 */
	serverError?: IServerError;

	/** popup is shown only if isOpened==true */
	isOpened?: boolean;

	/**
	 * A callback function to be called when user hits OK to close the popup.
	 * The function will be called after the popup is closed.
	 */
	onOK?: () => void | Promise<void>;
}

const AlertPopup: FC<IAlertPopup> = ({
	title = 'Something went wrong',
	errorMessage,
	onOK,
	serverError,
	isOpened = false
}: IAlertPopup) => {
	const buttons = [
		{
			title: 'OK',
			onClick: () => {
				logger.info('Error popup closed on OK click');
				onOK();
			}
		}
	];

	const renderErrorTitle = (): JSX.Element => <div className="error-title">{title}</div>;

	const renderErrorMessage = (): JSX.Element => {
		if (!errorMessage) {
			return null;
		}
		return <div className="error-message">{errorMessage}</div>;
	};

	const renderServerErrorDetails = (): JSX.Element => {
		const serverErrorMessage = ApiErrorHelper.getErrorMessage(serverError);
		if (!serverErrorMessage) {
			return null;
		}

		const isErrorRelatedToDomains = ApiErrorHelper.isErrorRelatedToDomains(serverError);
		if (isErrorRelatedToDomains) {
			return (
				<div className="error-details">
					<AllowedDomainsError serverError={serverError} />
				</div>
			);
		}

		if (serverErrorMessage.includes(SUPPORT_EMAIL)) {
			const splitMessage = serverErrorMessage.split(SUPPORT_EMAIL);
			return (
				<div className="error-details">
					{splitMessage[0] || ''}
					<ContactSupportMailLink />
					{splitMessage[1] || ''}
				</div>
			);
		}

		return <div className="error-details">{serverErrorMessage}</div>;
	};

	const isClientError = ApiErrorHelper.isClientError(serverError);

	return (
		<SiteScannerPopup
			title={title}
			isSrOnly={!isClientError}
			titleMode={TITLE_MODES.MAIN}
			buttons={buttons}
			isControlled
			isCentered
			isOpen={isOpened}
			className="error-popup"
			onClose={onOK}
			popupWidth="fit-content"
		>
			<div className="alert-popup">
				{!isClientError && renderErrorTitle()}
				{renderErrorMessage()}
				{renderServerErrorDetails()}
			</div>
		</SiteScannerPopup>
	);
};

export default AlertPopup;
