export const USAGE_DATA = 'usageData';
export const WEB_SDK_TESTS = 'webSdkTests';
export const WFA_SESSIONS = 'wfaSessions';
export const WFA_SESSION_SUMMARY = 'wfaSessionSummary';
export const WEB_SDK_TEST_LABELS = 'webSdkTestLabels';
export const WEB_SDK_TEST_DETAILS = 'webSdkTestDetails';
export const WEB_SDK_ISSUES_FILTER_OPTIONS = 'webSdkIssuesFilterOptions';
export const WEB_SDK_ISSUES = 'webSdkIssues';
export const PROPERTIES = 'properties';

export const WFA_USAGE_DATA = 'wfaUsageData';
export const WFA_SESSION_DETAILS = 'wfaSessionDetails';
export const WFA_SESSION_ALL_ISSUES = 'wfaSessionAllIssues';
export const WFA_SESSION_COMPONENTS = 'wfaSessionComponents';
export const WFA_SESSION_TOP_COMPONENTS = 'wfaSessionTopComponents';
export const WFA_SESSION_SEVERITIES = 'wfaSessionSeverities';
export const WFA_SESSION_TYPES_BREAKDOWN = 'wfaSessionTypesBreakdown';
export const WFA_SESSION_FILTER_OPTIONS = 'wfaSessionFilterOptions';

// common
export const PLATFORM_ISSUE_DETAILS = 'platformIssueDetails';
