import React, { createContext, useContext, useMemo } from 'react';

const RouteParamsContext = createContext(null);

export const RouteParamsProvider = ({ children, params }): JSX.Element => {
	const memoizedValue = useMemo(() => params, [params]);
	return (
		<RouteParamsContext.Provider value={memoizedValue}>{children}</RouteParamsContext.Provider>
	);
};

export const useRouteParams = (): Record<string, string> => {
	return useContext(RouteParamsContext);
};
