// This file was copied from site scanner. Planning to move it to ui-common.
import Logger from '../services/Logger';

export const asyncHandler = async <T>(promise, errorMsg?: string): Promise<[T, Error | null]> => {
	try {
		const data = await promise;
		return [data, null];
	} catch (error) {
		Logger.error(errorMsg, error);
		return [null, error];
	}
};
