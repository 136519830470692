// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-session-page {
  height: 100%;
  padding: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/wfa-dashboard/single-session-page/WfaSingleSessionPage.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;AACD","sourcesContent":[".wfa-session-page {\n\theight: 100%;\n\tpadding: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
