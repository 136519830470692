import React, { FC } from 'react';
import { useParams } from 'react-router';
import { SessionViews } from '../WfaDashboardConsts';
import { WfaSingleSessionTabs } from './WfaSingleSessionTabs';
import { WfaSessionOverview } from './views/overview/WfaSessionOverview';
import { WfaSessionComponents } from './views/components/WfaSessionComponents';
import { WfaSessionAllIssues } from './views/all-issues/WfaSessionAllIssues';
import { WfaSingleSessionHeader } from './header/WfaSingleSessionHeader';
import { TableStateProvider } from '../../../providers/dashboard/TableStateProvider';
import './WfaSingleSessionPage.scss';

interface IWfaSingleSessionPageProps {
	viewId?: SessionViews;
}

export const WfaSingleSessionPage: FC<IWfaSingleSessionPageProps> = ({
	viewId = SessionViews.OVERVIEW
}) => {
	const { sessionId } = useParams<{ [key: string]: string }>();

	const renderViewContent = (): JSX.Element => {
		switch (viewId) {
			case SessionViews.OVERVIEW: {
				return <WfaSessionOverview sessionId={sessionId} />;
			}

			case SessionViews.COMPONENTS: {
				return <WfaSessionComponents sessionId={sessionId} />;
			}

			case SessionViews.ALL_ISSUES: {
				return <WfaSessionAllIssues sessionId={sessionId} />;
			}
			default:
				return null;
		}
	};

	return (
		<div className="wfa-session-page">
			<WfaSingleSessionHeader sessionId={sessionId} />
			<WfaSingleSessionTabs viewId={viewId} sessionId={sessionId} />
			<TableStateProvider>{renderViewContent()}</TableStateProvider>
		</div>
	);
};
