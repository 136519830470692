import { EvLink } from '@evinced-private/ui-common';
import React, { FC } from 'react';

interface IKnowledgeBaseLinkProps {
	link: string;
}

export const KnowledgeBaseLink: FC<IKnowledgeBaseLinkProps> = ({ link }) => {
	if (!link) {
		return null;
	}
	return (
		<EvLink
			url={link}
			linkText="Read more in Evinced Knowledge Base"
			showIcon={false}
			openInNewTab={true}
		/>
	);
};
