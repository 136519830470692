// This file was copied from site scanner. Planning to move it to ui-common.
import { OptionType } from '@evinced-private/ui-common';

export interface IAzureProject extends OptionType {
	readonly id: string;
	readonly name: string;
	readonly description: string;
	readonly url: string;
	readonly state: string;
}

export interface IAzureOrganization {
	readonly accountId: string;
	readonly accountUri: string;
	readonly accountName: string;
	readonly properties: object;
}

export interface IAzureIssueType {
	readonly name: string;
	readonly referenceName: string;
	readonly defaultWorkItemType: object;
	readonly workItemTypes: IAzureWorkItemType[];
	readonly url: string;
}

export interface IAzureFieldType extends OptionType {
	readonly defaultValue: null;
	readonly allowedValues: string[];
	readonly helpText: string;
	readonly alwaysRequired: boolean;
	readonly dependentFields: [];
	readonly referenceName: string;
	readonly name: string;
	readonly url: string;
	readonly type?: string;
	readonly isIdentity?: boolean;
}

export interface IAzureFormFieldProps {
	readonly field: IAzureFieldType;
	readonly onChange: (value: string) => void;
}

export interface IAzureMetaData {
	readonly op: string;
	readonly path: string;
	readonly value: object | string;
}

export interface IAzureField {
	readonly name: string;
	readonly referenceName: string;
	readonly description: string;
	readonly type: string;
	readonly usage: string;
	readonly readOnly: boolean;
	readonly canSortBy: boolean;
	readonly isQueryable: boolean;
	readonly supportedOperations: [];
	readonly isIdentity: boolean;
	readonly isPicklist: boolean;
	readonly isPicklistSuggested: boolean;
	readonly url: string;
}

export interface IAzureClassificationNode {
	readonly id: number;
	readonly identifier: string;
	readonly children: IAzureClassificationNode[];
	readonly name: string;
	readonly structureType: string;
	readonly hasChildren: boolean;
	readonly path: string;
	readonly url: string;
}

export interface IAzureProjectType {
	readonly defaultWorkItemType: object;
	readonly name: string;
	readonly referenceName: string;
	readonly url: string;
	readonly workItemTypes: [];
}

export interface IAzureUser {
	readonly id: string;
	readonly email: string;
	readonly displayName: string;
}
export interface IAzureWorkItemType {
	readonly name: string;
	readonly url: string;
}

export enum AZURE_FIELDS {
	TITLE = 'System.Title',
	DESCRIPTION = 'System.Description',
	REASON = 'System.Reason',
	STATE = 'System.State',
	ASSIGNED_TO = 'System.AssignedTo'
}
