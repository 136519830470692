import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WFA_SESSION_TOP_COMPONENTS } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaSessionTopComponents } from 'src/services/wfa-dashboard/WfaDashboardService';
import { TWfaSessionTopComponentsResult } from 'src/services/wfa-dashboard/WfaDashboardService.types';

interface IUseWfaSessionComponentsQuery {
	sessionId: string;
}

export const useWfaTopSessionComponentsQuery = ({
	sessionId
}: IUseWfaSessionComponentsQuery): UseQueryResult<TWfaSessionTopComponentsResult> => {
	const componentsQuery = useQuery(
		[WFA_SESSION_TOP_COMPONENTS, sessionId],
		async (): Promise<TWfaSessionTopComponentsResult> =>
			getWfaSessionTopComponents({
				sessionId
			}),
		{
			keepPreviousData: true
		}
	);

	return componentsQuery;
};
