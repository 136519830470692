import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';
import { IssueDetailsQueryVariables } from '../IssueDetails.generated';

const ISSUE_ID_PARAM_NAME = 'issueId';
const RUN_ID_PARAM_NAME = 'runId';

export const buildIssueDetailsVariables = (
	issueId: string,
	runId: string
): IssueDetailsQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<IssueDetailsQueryVariables>();

	return variablesBuilder
		.addConditionParam(ISSUE_ID_PARAM_NAME, issueId)
		.addConditionParam(RUN_ID_PARAM_NAME, runId)
		.getVariables();
};
