import React, { FC } from 'react';
import { useSkipLinks } from '@evinced-private/ui-common';
import { HorizontalDivider } from '../../../components/common/horizontal-divider/HorizontalDivider';
import { useTableState } from '../../../providers/dashboard/TableStateProvider';
import { WFA_SESSIONS_TABLE_ID } from '../../dashboards-container/dashboard_consts';
import { WebSdkTestsPageError } from '../../web-sdk-dashboard/error-pages/WebSdkTestsPageError';
import { useWfaUsageDataQuery } from '../hooks/useWfaUsageDataQuery';
import { useWfaSessionsQuery } from '../hooks/useWfaSessionsQuery';
import { WfaSessionsPageHeader } from './sections/header/WfaSessionsHeader';
import { WfaSessionsUsage } from './sections/usage/WfaSessionsUsage';
import { WfaSessionsTable } from './sections/table/WfaSessionsTable';
import './WfaSessionsPage.scss';

const WfaSessionsPage: FC = () => {
	const { getTableStateById, onFilterChange } = useTableState();
	const { filters, tableSort, paginationInfo } = getTableStateById(WFA_SESSIONS_TABLE_ID);

	const usageDataQuery = useWfaUsageDataQuery(filters.sessionsPeriod, filters.searchValue);

	const sessionsQuery = useWfaSessionsQuery({
		page: paginationInfo.page,
		pageSize: paginationInfo.sizePerPage,
		sort: tableSort?.[0],
		filters
	});
	useSkipLinks(!usageDataQuery.isLoading && !sessionsQuery.isLoading);

	const unfilteredTotal = sessionsQuery.data?.unfilteredTotal ?? 0;

	if (sessionsQuery?.isError || usageDataQuery?.isError) {
		return <WebSdkTestsPageError />;
	}

	if (!sessionsQuery?.isLoading && !sessionsQuery?.isPreviousData && unfilteredTotal === 0) {
		// create wfa error page
		return <WebSdkTestsPageError isNoData />;
	}

	return (
		<div className="wfa-sessions-page">
			<WfaSessionsPageHeader filters={filters} onFilterChange={onFilterChange} />
			<HorizontalDivider />
			<WfaSessionsUsage
				data={usageDataQuery.data}
				isLoading={usageDataQuery.isLoading}
				selectedPeriodLabel={filters.sessionsPeriod?.[0]?.label}
			/>
			<WfaSessionsTable sessionsQuery={sessionsQuery} unfilteredTotal={unfilteredTotal} />
		</div>
	);
};

export default WfaSessionsPage;
