import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useSkipLinks, EvErrorCard } from '@evinced-private/ui-common';
import { WEB_SDK_ERROR_MSG } from '../dashboards-container/errorMessages';
import { WEB_SDK_TEST_RUN_TIME_PARAM, WEB_SDK_TEST_NAME_PARAM } from './web-sdk-consts';
import WebSdkDetailsBlock from '../../components/web-sdk/WebSdkDetailsBlock';
import './WebSdkIssuePage.scss';

const WebSdkIssuePage: FC = () => {
	const location = useLocation();
	const params = location.search;
	const runTime = new URLSearchParams(params).get(WEB_SDK_TEST_RUN_TIME_PARAM);
	const testName = new URLSearchParams(params).get(WEB_SDK_TEST_NAME_PARAM);

	useSkipLinks(true);
	const [error, setError] = useState(null);
	const { runId, issueId } = useParams<{ [key: string]: string }>();

	useEffect(() => {
		// setting white background to main container since it is the only page with such a colour
		const app = document.getElementsByClassName('app')[0] as HTMLElement;
		app.style.backgroundColor = 'white';
		// setting boxShadow to header since it is the only page with such a header style
		const header = document.getElementsByClassName('ev-header')[0] as HTMLElement;
		header.style.boxShadow = '0px 1px 5px rgba(51, 64, 85, 0.1)';
	}, []);

	return (
		<div className="issue-details-page">
			{error ? (
				<EvErrorCard title="Error" contactUsMessage={WEB_SDK_ERROR_MSG} />
			) : (
				<WebSdkDetailsBlock
					runId={runId}
					issueId={issueId}
					runTime={runTime ?? ''}
					testName={testName}
					isFullPageMode
					setError={setError}
				/>
			)}
		</div>
	);
};

export default WebSdkIssuePage;
