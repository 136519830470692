import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const shortMonth = 'MMM D, YYYY, h:mm a';
const longMonth = 'MMMM D, YYYY, h:mm a';

export function formatDate(dateString, format = longMonth): string {
	return dayjs(dateString).format(format);
}

function formatDateToUtc(dateString, format = longMonth): string {
	const utcDayjs = dayjs.extend(utc).utc(dateString);
	return utcDayjs.format(format);
}

export function formatShortMonthDatetime(dateString, toUtc = false): string {
	return toUtc ? formatDateToUtc(dateString, shortMonth) : formatDate(dateString, shortMonth);
}
