import {
	EvSelectOptionValueType,
	OptionType,
	SORT_ORDER,
	TableSortOption
} from '@evinced-private/ui-common';

type GetSearchFields<T> = T extends {
	textSearch?: { searchFields: Array<infer TextSearchFields> } | null;
}
	? TextSearchFields[]
	: never[];

type GetSortField<T> = T extends { sort?: { fields: Array<infer SortField> } | null }
	? SortField
	: never;

type GetSortFieldsMap<T> = Record<string, GetSortField<T>>;

type GetSortOrder<T> = T extends { sort?: { order?: infer SortOrder } | null } ? SortOrder : never;

type GetSortOrderMap<T> = Record<SORT_ORDER, GetSortOrder<T>>;

type ConstructorParams<T> = {
	searchFields?: GetSearchFields<T>;
	sortFieldsMap?: GetSortFieldsMap<T>;
	sortOrderMap?: GetSortOrderMap<T>;
};

export class QueryVariablesBuilder<QueryVariables> {
	// #region private
	private variables: QueryVariables;

	private searchFields?: GetSearchFields<QueryVariables>;

	private sortFieldsMap?: GetSortFieldsMap<QueryVariables>;

	private sortOrderMap?: GetSortOrderMap<QueryVariables>;

	private buildConditionParam(values?: EvSelectOptionValueType | EvSelectOptionValueType[]): {
		value: EvSelectOptionValueType[];
	} {
		let value: EvSelectOptionValueType[];
		if (values) {
			if (Array.isArray(values)) {
				value = values;
			} else {
				value = [values];
			}
		} else {
			value = [];
		}
		const conditionObj = { value };
		return conditionObj;
	}

	// #endregion

	// #region public
	constructor(params?: ConstructorParams<QueryVariables>) {
		this.variables = {} as QueryVariables;
		this.searchFields = params?.searchFields;
		this.sortFieldsMap = params?.sortFieldsMap;
		this.sortOrderMap = params?.sortOrderMap;
	}

	addPaginationParams(page: number, pageSize: number): this {
		const offset = (page - 1) * pageSize;
		this.variables = {
			...this.variables,
			offset,
			limit: pageSize
		};

		return this;
	}

	addTextSearchParam(
		searchValue: string | string[],
		customSearchFields?: GetSearchFields<QueryVariables>,
		exact?: boolean
	): this {
		const currentSearchFields = customSearchFields || this.searchFields;
		if (searchValue.length && currentSearchFields) {
			const textSearchParam = {
				insensitive: true,
				searchFields: currentSearchFields,
				searchValues: [].concat(searchValue)
			};
			this.variables = {
				...this.variables,
				[exact ? 'textSearchExact' : 'textSearch']: textSearchParam
			};
		}
		return this;
	}

	addSortParam(sort?: TableSortOption, customSortFields?: string[]): this {
		const fieldsMap = this.sortFieldsMap;
		const orderMap = this.sortOrderMap;

		if (sort && orderMap) {
			let sortParam: {
				fields: GetSortField<QueryVariables>[];
				order: GetSortOrder<QueryVariables>;
			};

			const customSortFieldsMapped =
				customSortFields?.map((customField) => fieldsMap?.[customField])?.filter(Boolean) ?? [];

			if (customSortFieldsMapped.length) {
				sortParam = {
					fields: [...customSortFieldsMapped],
					order: orderMap[sort.order]
				};
			} else if (fieldsMap?.[sort?.dataField]) {
				sortParam = {
					fields: [fieldsMap[sort.dataField]],
					order: orderMap[sort.order]
				};
			}

			this.variables = {
				...this.variables,
				sort: sortParam
			};
		}
		return this;
	}

	addFilterParam(key: keyof QueryVariables, options?: OptionType[]): this {
		if (options?.length) {
			const filter = this.buildConditionParam(
				options.filter((option) => option.value !== '*').map((option) => option.value as string)
			);
			if (filter.value.length) {
				this.variables = {
					...this.variables,
					[key]: filter
				};
			}
		}

		return this;
	}

	addConditionParam(
		key: keyof QueryVariables,
		values?: EvSelectOptionValueType | EvSelectOptionValueType[]
	): this {
		const conditionObj = this.buildConditionParam(values);
		if (conditionObj.value.length) {
			this.variables = {
				...this.variables,
				[key]: conditionObj
			};
		}

		return this;
	}

	getVariables(): QueryVariables {
		return this.variables;
	}

	// #endregion
}
