export const APPLY_BUTTON_DAID = 'apply-button';
export const RESET_BUTTON_DAID = 'reset-button';
export const ERROR_LAYOUT_DAID = 'error-layout';
export const TABLE_LABEL_DAID = 'table-label';
export const SUMMARY_PANE_ITEM_DAID = 'summary-pane-item';
export const SUMMARY_PANE_ITEM_LABEL_DAID = 'summary-pane-item-label';
export const SUMMARY_PANE_ITEM_VALUE_DAID = 'summary-pane-item-value';
export const SUMMARY_PANE_ITEM_LIFT_DAID = 'summary-pane-item-lift';

export const TABS_DAID = 'navigation-tabs';

export const DATA_ITEM_DAID = 'data-item';

export const BREADCRUMBS_DAID = 'breadcrumbs';
export const BREADCRUMB_ITEM_DAID = 'breadcrumb-item';
export const TAG_DAID = 'tag';
