export const getPropertiesString = (
	propertyIds: string[],
	propertiesMap?: Map<string, string>
): string | null => {
	if (!propertiesMap?.size) {
		return null;
	}

	const result = propertyIds
		.map((propertyId) => propertiesMap.get(propertyId))
		.filter(Boolean)
		.join(', ');

	return result.length ? result : null;
};
