import {
	BUTTON_TYPES,
	EvButton,
	EvSelect,
	EvTextInputSearch,
	OptionType,
	SomeItemsSelectedTextFunction,
	TABLE_FILTERS_SR_SUMMARY
} from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FilterTypesNames } from '../../consts/app_consts';
import IdFormatterHelper from '../../helpers/IdFormatterHelper';
import EvSection from '../common/ev-section/EvSection';
import {
	areFiltersEqual,
	FILTER_TYPES,
	IFilterOptions,
	emptyFilterOptions,
	FilterOptionKey,
	syncSelectedFiltersWithOptions
} from './DataFilterHelper';
import './DataFilter.scss';

interface IDataFilterProps {
	filterOptions: IFilterOptions;
	filterValues: IFilterOptions;
	onApply: (filterValues: IFilterOptions) => void;
	onReset: () => IFilterOptions;
	textSearchEnabled?: boolean;
	osFilterEnabled?: boolean;
	appsFilterEnabled?: boolean;
	versionsFilterEnabled?: boolean;
	labelsFilterEnabled?: boolean;
	hideLabel?: boolean;
	disableButtons?: boolean;
	tableId?: string;
}

const DataFilter: FC<IDataFilterProps> = ({
	filterOptions,
	filterValues,
	onApply,
	onReset,
	textSearchEnabled,
	osFilterEnabled = false,
	appsFilterEnabled = false,
	versionsFilterEnabled = false,
	labelsFilterEnabled = false,
	hideLabel,
	disableButtons,
	tableId
}: IDataFilterProps) => {
	const [currentSearchValueFilter, setCurrentSearchValueFilter] = useState<string>(
		filterValues?.searchValue
	);

	const initSelectedValues = useCallback(
		(key: FilterOptionKey): OptionType[] => {
			return syncSelectedFiltersWithOptions(tableId, key, filterValues?.[key], filterOptions);
		},
		[filterValues, filterOptions, tableId]
	);

	const [currentOsFilter, setCurrentOsFilter] = useState<OptionType[]>(() => {
		return initSelectedValues(FilterTypesNames.OS);
	});
	const [currentAppsFilter, setCurrentAppsFilter] = useState<OptionType[]>(() => {
		return initSelectedValues(FilterTypesNames.APPS);
	});

	const [currentVersionsFilter, setCurrentVersionsFilter] = useState<OptionType[]>(() => {
		return initSelectedValues(FilterTypesNames.VERSIONS);
	});

	const [currentLabelsFilter, setCurrentLabelsFilter] = useState<OptionType[]>(() => {
		return initSelectedValues(FilterTypesNames.LABELS);
	});

	const currentFilters = useMemo(
		() => ({
			searchValue: currentSearchValueFilter,
			// instead of updating current filters in a useEffect when filterOptions changes
			// have a derived memoized value that's calculated from current selections AND filterOptions
			os: syncSelectedFiltersWithOptions(
				tableId, FilterTypesNames.OS, currentOsFilter, filterOptions),
			apps: syncSelectedFiltersWithOptions(
				tableId, FilterTypesNames.APPS, currentAppsFilter, filterOptions),
			versions: syncSelectedFiltersWithOptions(
				tableId,
				FilterTypesNames.VERSIONS,
				currentVersionsFilter,
				filterOptions
			),
			userLabels: syncSelectedFiltersWithOptions(
				tableId,
				FilterTypesNames.LABELS,
				currentLabelsFilter,
				filterOptions
			)
		}),
		[
			tableId,
			currentSearchValueFilter,
			currentOsFilter,
			currentAppsFilter,
			currentVersionsFilter,
			currentLabelsFilter,
			filterOptions
		]
	);

	const onFilterChange = useCallback(
		(filters: OptionType[], filterType: FILTER_TYPES): void => {
			if (filterType === FILTER_TYPES.OS) {
				setCurrentOsFilter(
					syncSelectedFiltersWithOptions(tableId, FilterTypesNames.OS, filters, filterOptions)
				);
			}

			if (filterType === FILTER_TYPES.APP) {
				setCurrentAppsFilter(
					syncSelectedFiltersWithOptions(tableId, FilterTypesNames.APPS, filters, filterOptions)
				);
			}

			if (filterType === FILTER_TYPES.VERSION) {
				setCurrentVersionsFilter(
					syncSelectedFiltersWithOptions(tableId, FilterTypesNames.VERSIONS, filters, filterOptions)
				);
			}

			if (filterType === FILTER_TYPES.LABELS) {
				setCurrentLabelsFilter(
					syncSelectedFiltersWithOptions(tableId, FilterTypesNames.LABELS, filters, filterOptions)
				);
			}
		},
		[filterOptions, tableId]
	);

	// TODO: consider removing if and use setStates directly in line 286
	const onInputChange = useCallback((value: string, filterType: FILTER_TYPES): void => {
		if (filterType === FILTER_TYPES.SEARCH_VALUE_ID) {
			setCurrentSearchValueFilter(value);
		}
	}, []);

	const [applyDisabled, resetDisabled] = useMemo((): [boolean, boolean] => {
		const stateFilters = filterValues || emptyFilterOptions(tableId);
		const noChange = areFiltersEqual(currentFilters, stateFilters);
		const initialState = areFiltersEqual(currentFilters, emptyFilterOptions(tableId));
		return [noChange, initialState && noChange];
	}, [filterValues, currentFilters, tableId]);

	const handleReset = useCallback(() => {
		// TODO:  Ask product if we need the rest of the filters?
		// MFA Sessions Filters
		const initialValues = onReset();
		setCurrentSearchValueFilter(initialValues.searchValue);
		setCurrentOsFilter(initialValues.os);
		setCurrentAppsFilter(initialValues.apps);
		setCurrentVersionsFilter(initialValues.versions);
		setCurrentLabelsFilter(initialValues.userLabels);
	}, [onReset]);

	const renderFilter = useCallback(
		(
			placeHolder: string,
			allSelectedPlaceHolder: string,
			someItemsSelectedText: SomeItemsSelectedTextFunction,
			filterType: FILTER_TYPES,
			options: OptionType[],
			values: OptionType[]
		): JSX.Element => {
			const filterMultiselectId = IdFormatterHelper.formatTextToId(placeHolder);
			return (
				<div className={`scan-data-filter-dropdown ${filterMultiselectId}`}>
					<EvSelect
						id={`${filterMultiselectId}-select`}
						options={options}
						isMulti={true}
						onChange={(filters) => {
							onFilterChange(filters, filterType);
						}}
						allowSelectAll={true}
						isSearchable={true}
						value={values || []}
						placeholder={placeHolder}
						allSelectedPlaceholder={allSelectedPlaceHolder}
						someItemsSelectedText={someItemsSelectedText}
						withMultilineOptions={filterType === FILTER_TYPES.VALIDATION_ID}
					/>
				</div>
			);
		},
		[onFilterChange]
	);

	const osFilter = useMemo((): JSX.Element => {
		if (!filterOptions?.os) {
			return null;
		}
		return renderFilter(
			'Select OS',
			'All OS',
			(numberOfselected: number) => `OS (${numberOfselected})`,
			FILTER_TYPES.OS,
			filterOptions.os,
			currentFilters.os
		);
	}, [currentFilters.os, filterOptions, renderFilter]);

	const appFilter = useMemo((): JSX.Element => {
		if (!filterOptions?.apps) {
			return null;
		}
		return renderFilter(
			'Select App',
			'All Apps',
			(numberOfselected: number) => `Apps (${numberOfselected})`,
			FILTER_TYPES.APP,
			filterOptions.apps,
			currentFilters.apps
		);
	}, [currentFilters.apps, filterOptions, renderFilter]);

	const versionFilter = useMemo((): JSX.Element => {
		if (!filterOptions?.versions) {
			return null;
		}
		return renderFilter(
			'Select Version',
			'All Versions',
			(numberOfselected: number) => `Versions (${numberOfselected})`,
			FILTER_TYPES.VERSION,
			filterOptions.versions,
			currentFilters.versions
		);
	}, [currentFilters.versions, filterOptions, renderFilter]);

	const labelsFilter = useMemo((): JSX.Element => {
		if (!filterOptions?.userLabels) {
			return null;
		}
		return renderFilter(
			'Select Labels',
			'All Labels',
			(numberOfselected: number) => `Labels (${numberOfselected})`,
			FILTER_TYPES.LABELS,
			filterOptions.userLabels,
			currentFilters.userLabels
		);
	}, [currentFilters.userLabels, filterOptions, renderFilter]);

	const searchValueFilter = useMemo(() => {
		if (!textSearchEnabled) {
			return null;
		}
		return (
			<div className="search-input">
				<EvTextInputSearch
					value={currentSearchValueFilter}
					onChange={(value: string) => {
						onInputChange(value, FILTER_TYPES.SEARCH_VALUE_ID);
					}}
					onConfirm={() => onApply(currentFilters)}
				/>
			</div>
		);
	}, [textSearchEnabled, currentSearchValueFilter, currentFilters, onApply, onInputChange]);

	const renderSrSummary = (): JSX.Element => {
		const filteredOs = filterValues?.os?.length || 0;
		const filteredApps = filterValues?.apps?.length || 0;
		const filteredVersions = filterValues?.versions?.length || 0;
		const filteredLables = filterValues?.userLabels?.length || 0;

		const allOs = filterOptions?.os?.length || 0;
		const allApps = filterOptions?.apps?.length || 0;
		const allVersions = filterOptions?.versions?.length || 0;
		const allLables = filterOptions?.userLabels?.length || 0;

		// TODO:extract to a function
		const osText = filteredOs === allOs || !filterValues?.os ? 'all' : filteredOs;
		const appsText = filteredApps === allApps || !filterValues?.apps ? 'all' : filteredApps;
		const versionsText =
			filteredVersions === allVersions || !filterValues?.versions ? 'all' : filteredVersions;
		const labelsText =
			filteredVersions === allLables || !filterValues?.userLabels ? 'all' : filteredLables;

		// MFA Sessions

		return (
			<div id={TABLE_FILTERS_SR_SUMMARY} style={{ display: 'none' }}>
				Table Filter Results:
				{osFilterEnabled && `os: ${osText}`}
				{appsFilterEnabled && `apps: ${appsText}`}
				{versionsFilterEnabled && `versions: ${versionsText}`}
				{labelsFilterEnabled && `labels: ${labelsText}`}
			</div>
		);
	};

	return (
		<EvSection className={classNames('scan-data-filter', tableId)} ariaLabel="Table filters">
			{!hideLabel && <label>Filter results</label>}
			{searchValueFilter}
			{osFilter}
			{appFilter}
			{versionFilter}
			{labelsFilter}
			<EvButton
				type={BUTTON_TYPES.ACTION}
				title="Apply filters"
				onClick={() => onApply(currentFilters)}
				disabled={disableButtons || applyDisabled}
			>
				Apply
			</EvButton>
			<EvButton
				type={BUTTON_TYPES.ACTION}
				title="Clear Filters"
				onClick={handleReset}
				disabled={disableButtons || resetDisabled}
			>
				Clear
			</EvButton>
			{renderSrSummary()}
		</EvSection>
	);
};

export default DataFilter;
