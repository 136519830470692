// This file was copied from site scanner. Planned to move it to ui-common
import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PROPERTIES } from 'src/providers/reactQueryProvider/QueryKeys';
import { populatePropertiesLabel } from 'src/pages/web-sdk-dashboard/web-sdk-helpers';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { getBaseURL } from 'src/helpers/UrlNormalizingHelper';
import { getPropertiesMap } from 'src/services/properties/PropertiesService';
import TogglesService, { DevelopmentToggles } from 'src/services/TogglesService';
import DetailsBlock from '../common/details-block/DetailsBlock';
import CopyToClipboard from '../common/copy-to-clipboard/CopyToClipboard';
import TicketsSystems from '../tickets-systems/TicketsSystems';
import { mapPlatformIssueDetailsToReport } from '../common/details-block/DetailsBlockHelper';
import { ProductType } from '../../interfaces/Tenant';
import { useIssueDetailsQuery } from '../../services/common/hooks/useIssueDetailsQuery';

interface IWebSdkIssueDetailsProps {
	runId: string;
	issueId: string;
	setError?: (error: unknown) => void;
	isFullPageMode?: boolean;
	runTime: string;
	testName?: string;
}

const enableWebSdkProperties = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES
);

const WebSdkDetailsBlock: FC<IWebSdkIssueDetailsProps> = ({
	runId,
	issueId,
	setError,
	isFullPageMode,
	runTime,
	testName
}: IWebSdkIssueDetailsProps) => {
	const issueDetailsQuery = useIssueDetailsQuery({ runId, issueId, product: ProductType.WEB_SDK });

	const propertiesQuery = useQuery([PROPERTIES], getPropertiesMap, {
		enabled: enableWebSdkProperties
	});

	useEffect(() => {
		if (issueDetailsQuery.isError) {
			setError?.(issueDetailsQuery.error);
		}
	}, [issueDetailsQuery.isError, issueDetailsQuery.error, setError]);

	const issuePageUrl = RoutesHelper.getWebSdkIssueInfoPath({
		runId,
		issueId,
		runTime,
		testName
	});

	const urlToCopy = `${getBaseURL()}${issuePageUrl}`;

	const issue = issueDetailsQuery.data;
	const {
		type,
		severity,
		elements,
		level,
		labels,
		summary,
		cause,
		howToFix,
		effect,
		knowledgeBaseLink,
		tags,
		screenshotDetails
	} = issue || {};
	const { pageUrl, pageTitle } = elements?.[0] ?? {};
	const isPropertiesLoading =
		issueDetailsQuery.isLoading || (enableWebSdkProperties && propertiesQuery.isLoading);

	const labelsToShow = isPropertiesLoading
		? labels
		: populatePropertiesLabel(labels, issue.propertyIds, propertiesQuery.data);

	const openIssueInNewTab = useCallback((): void => {
		window.open(issuePageUrl, '_blank');
	}, [issuePageUrl]);

	const topRowActions = (
		<div className="action-buttons">
			{/* In common - pass topBarActionsSection */}
			<CopyToClipboard textToCopy={urlToCopy} buttonType={BUTTON_TYPES.SECONDARY} />
			{!isFullPageMode && (
				<EvButton
					type={BUTTON_TYPES.SECONDARY}
					title="Open in a New Tab"
					onClick={openIssueInNewTab}
				>
					Open in a New Tab
				</EvButton>
			)}
			{!issueDetailsQuery.isLoading && (
				<TicketsSystems issue={mapPlatformIssueDetailsToReport(issue)} />
			)}
		</div>
	);

	return (
		<DetailsBlock
			issue={{
				type,
				severity,
				elements,
				level,
				labels: labelsToShow,
				summary,
				cause,
				howToFix,
				effect,
				knowledgeBaseLink,
				tags,
				pageUrl,
				pageTitle,
				screenshotDetails
			}}
			isLoading={issueDetailsQuery.isLoading}
			isFullPageMode={isFullPageMode}
			topRowActions={topRowActions}
		/>
	);
};

export default WebSdkDetailsBlock;
