import { EvIcon, EvLink, EvTitle, TITLE_MODES } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import EvincedLogo from '../../components/icons/EvincedLogo.svg';
import './NotSupportedScreenSizePage.scss';

const NotSupportedScreenSizePage: FC = () => {
	return (
		<div className="not-supportd-screen-size-page">
			<div className="card">
				<EvIcon icon={EvincedLogo} className="evinced-logo" />
				<EvTitle
					titleText="Sorry, this device type is currently not supported."
					mode={TITLE_MODES.MAIN}
				/>
				<div className="main-text">
					This application is not optimized for mobile device resolutions and touch screens.
				</div>
				<EvLink
					className="link-to-evinced"
					url="https://www.evinced.com/"
					linkText="Back to evinced.com website"
				/>
			</div>
		</div>
	);
};

export default NotSupportedScreenSizePage;
