// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web-sdk-dashboard-error-msg {
  white-space: pre-wrap;
}
.web-sdk-dashboard-error-msg .ev-popup .ev-button-container {
  margin-top: 20px;
}
.web-sdk-dashboard-error-msg .documentation-link {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/web-sdk-dashboard/error-pages/WebSdkError.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;AACD;AAEE;EACC,gBAAA;AAAH;AAIC;EACC,gBAAA;AAFF","sourcesContent":[".web-sdk-dashboard-error-msg {\n\twhite-space: pre-wrap;\n\n\t.ev-popup {\n\t\t.ev-button-container {\n\t\t\tmargin-top: 20px;\n\t\t}\n\t}\n\n\t.documentation-link {\n\t\tmargin-top: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
