import { OptionType } from '@evinced-private/ui-common';
import { isEqual } from 'lodash-es';
import { SELECT_ALL_OPTION, SELECT_ALL_VALUE } from '../../consts/app_consts';
import SeverityService from '../../services/severities/SeverityService';
import { DEFAULT_PERIOD_OPTION } from '../../pages/web-sdk-dashboard/web-sdk-consts';
import {
	MFA_SESSIONS_TABLE_ID,
	MSDK_SESSIONS_TABLE_ID,
	WEB_SDK_TEST_ISSUES_TABLE_ID,
	WEB_SDK_TEST_TABLE_ID,
	WFA_SESSIONS_TABLE_ID,
	WFA_SESSION_ISSUES_TABLE_ID,
	WFA_SESSION_COMPONENTS_TABLE_ID
} from '../../pages/dashboards-container/dashboard_consts';

enum FieldOptionName {
	SEVERITY = 'severity',
	TYPE = 'type',
	TAGS = 'tags'
}

export type FilterOptionKey = 'os' | 'apps' | 'versions' | 'userLabels';

export enum FILTER_TYPES {
	SEARCH_VALUE_ID,
	LABELS,
	COMPONENT_ID,
	VALIDATION_ID,
	SEVERITY,
	TAG,
	OS,
	APP,
	VERSION
}

interface IMobileSessionsFilterOption {
	searchValue?: string;
	os?: OptionType[];
	apps?: OptionType[];
	versions?: OptionType[];
}

interface IWfaSessionsFilterOption {
	searchValue?: string;
	sessionsPeriod?: OptionType[];
	createdBy?: OptionType[];
}

interface IMobileSdkTestsFilterOption {
	searchValue?: string;
	userLabels?: OptionType[];
}

export interface IWebSdkTestsFilterOption {
	searchValue?: string;
	testPeriod?: OptionType[];
	labels?: OptionType[];
	properties?: OptionType[];
}

export interface IWebSdkTestIssuesFilterOption {
	searchValue?: string;
	severities?: OptionType[];
	types?: OptionType[];
	levels?: OptionType[];
	urls?: OptionType[];
	properties?: OptionType[];
}

export interface IFilterOptions
	extends IWebSdkTestsFilterOption,
		IMobileSessionsFilterOption,
		IMobileSdkTestsFilterOption,
		IWfaSessionsFilterOption,
		IWebSdkTestIssuesFilterOption {}

export const orderByMethod = (values: string[] = [], option = ''): string[] => {
	if (option === FieldOptionName.SEVERITY) {
		return SeverityService.sortSeverities(values);
	}
	return values.sort();
};

const getArrayValues = (arr: OptionType[]): string[] => arr.map((option) => option.value as string);

const clearSelectAll = (arr: string[] = []): string[] => {
	return arr.filter((val) => val !== '*');
};

const isFilterEqual = (current: OptionType[], state: OptionType[]): boolean => {
	if (typeof current !== typeof state) {
		return false;
	}
	if (!current && !state) {
		return true;
	}
	if (typeof current === 'string') {
		return current === state;
	}
	const currentArr = clearSelectAll(getArrayValues(current)).sort();
	const stateArr = clearSelectAll(getArrayValues(state)).sort();
	return isEqual(currentArr, stateArr);
};

export const areFiltersEqual = (current: IFilterOptions, state: IFilterOptions): boolean =>
	Object.keys(current).every((filter) => isFilterEqual(current?.[filter], state?.[filter]));

export const defaultEmptyFilterOptions: IFilterOptions = {
	searchValue: '',
	os: [],
	apps: [],
	versions: [],
	userLabels: []
};

const defaultWebSdkEmptyFilterOptions: IWebSdkTestsFilterOption = {
	searchValue: '',
	testPeriod: [DEFAULT_PERIOD_OPTION],
	labels: []
};

const defaultWfaEmptyFilterOptions: IFilterOptions = {
	searchValue: '',
	sessionsPeriod: [DEFAULT_PERIOD_OPTION]
};

const defaultWebSdkIssuesEmptyFilterOptions: IWebSdkTestIssuesFilterOption = {
	searchValue: '',
	severities: [],
	types: [],
	urls: []
};

const defaultMobileSdkEmptyFilterOptions: IMobileSdkTestsFilterOption = {
	searchValue: '',
	userLabels: []
};

const defaultMFAEmptyFilterOptions: IMobileSessionsFilterOption = {
	searchValue: '',
	os: [],
	apps: [],
	versions: []
};

const defaultWfaSingleSessionFilterOptions: IFilterOptions = {
	searchValue: '',
	severities: [],
	types: [],
	urls: [],
	levels: []
};

export const emptyFilterOptions = (tableId): IFilterOptions => {
	switch (tableId) {
		case WEB_SDK_TEST_TABLE_ID:
			return defaultWebSdkEmptyFilterOptions;
		case WEB_SDK_TEST_ISSUES_TABLE_ID:
			return defaultWebSdkIssuesEmptyFilterOptions;
		case MSDK_SESSIONS_TABLE_ID:
			return defaultMobileSdkEmptyFilterOptions;
		case MFA_SESSIONS_TABLE_ID:
			return defaultMFAEmptyFilterOptions;
		case WFA_SESSIONS_TABLE_ID:
			return defaultWfaEmptyFilterOptions;
		case WFA_SESSION_ISSUES_TABLE_ID:
		case WFA_SESSION_COMPONENTS_TABLE_ID:
			return defaultWfaSingleSessionFilterOptions;
		default:
			return defaultEmptyFilterOptions;
	}
};

// This function normalizes the selected filter values.
// I. E. it modifes the values to be in accordance with current filter options.
// If all currently available options are selected it selects the SELECT_ALL_OPTION.
// If SELECT_ALL_OPTION is selected it selects all options
// If there's a selected option that's not in available filter options anymore it removes it
// This can happen because filter options update as the table updates
export const syncSelectedFiltersWithOptions = (
	tableId: string,
	key: FilterOptionKey,
	values: OptionType[],
	options: IFilterOptions
): OptionType[] => {
	let list: OptionType[] = [];

	if (!values) {
		list = emptyFilterOptions(tableId)[key];
	} else {
		list = values.some((o: OptionType) => o.value === SELECT_ALL_VALUE)
			? options[key] ?? emptyFilterOptions(tableId)[key]
			: values;
	}

	if (options[key]?.length) {
		list = list.filter((option) => options[key].some((fOption) => fOption.value === option.value));

		// Check if all filter dropdown options are selected, if so add 'Select all' option.
		// We do it since before selecting "Select All" this value is not returned from EvSelect
		// even though it's selected, so we need to add it manually.
		list =
			list.length === options[key].length &&
			!list.some((option) => option.value === SELECT_ALL_VALUE)
				? [SELECT_ALL_OPTION, ...list]
				: list;
	} else {
		list = [];
	}

	return list;
};

export default {
	orderByMethod,
	areFiltersEqual,
	emptyFilterOptions,
	syncSelectedFiltersWithOptions
};
