export const MSDK_SESSIONS_TABLE_ID = 'msdk-tests-table';
export const MFA_SESSIONS_TABLE_ID = 'mfa-sessions-table';
export const WEB_SDK_TEST_TABLE_ID = 'wsdk-tests-table';
export const WFA_SESSIONS_TABLE_ID = 'wfa-sessions-table';
export const WFA_SESSION_ISSUES_TABLE_ID = 'wfa-session-all-issues';
export const WFA_SESSION_COMPONENTS_TABLE_ID = 'wfa-session-components';
export const WEB_SDK_TEST_ISSUES_TABLE_ID = 'wsdk-test-issues-table';

export const MFA_DASHBOARD_HEADER_TITLE = 'Mobile Flow Analyzer';
export const MSDK_DASHBOARD_HEADER_TITLE = 'Mobile SDKs';
export const WEB_SDK_DASHBOARD_HEADER_TITLE = 'Web SDKs';

export const LAST_MONTH_USAGE_TOOLTIP =
	'Usage within the last 30 days compared to the previous period (if applicable).';

export const WEB_SDK_DOCUMENTATION_LINK = 'https://developer.evinced.com/sdks-for-web-apps/intro';
