import { upperFirst } from 'lodash-es';
import { normalizeCustomField } from '../../../../../helpers/GQLRequestsHelper/QueryResultsTransformHelper';
import { ArrayElement, returnNonNull } from '../../../../../helpers/ArraysHelper';
import { IWebSdkTest, TWebSdkTestsResult } from '../../../WebSdkDashboardService.types';
import { TestsQuery } from '../Tests.generated';

type Test = NonNullable<ArrayElement<NonNullable<TestsQuery['wsdk_test_runs']>>>;

const getAllLabelValues = (test: Test): string[] => [
	...test.environments.map(upperFirst),
	...test.flows.map(upperFirst),
	...(test.custom_fields
		?.map(normalizeCustomField)
		?.filter(returnNonNull)
		?.map(([, labelValue]) => labelValue) ?? [])
];

export const transformTestsResults = (rawResults: TestsQuery): TWebSdkTestsResult => {
	const rows: IWebSdkTest[] =
		rawResults.wsdk_test_runs?.filter(returnNonNull).map((test) => ({
			runId: test.run_id,
			totalIssues: test.total_issues,
			criticalIssues: test.critical_issues,
			runTime: test.run_time,
			testName: test.test_name,
			testPath: test.test_path,
			propertyIds: test.property_ids,
			labels: getAllLabelValues(test)
		})) ?? [];

	let total = 0;
	if (rawResults.total?.length) {
		total = rawResults.total[0]?.run_id_count ?? 0;
	}

	let unfilteredTotal = 0;
	if (rawResults.unfiltered_total?.length) {
		unfilteredTotal = rawResults.unfiltered_total[0]?.run_id_count ?? 0;
	}

	return {
		rows,
		total,
		unfilteredTotal
	};
};
