import { IWfaSessionDetails } from 'src/services/wfa-dashboard/WfaDashboardService.types';
import { formatShortMonthDatetime } from 'src/helpers/DateFormatHelper';
import { ITagGroup } from '../common/tags-block/TagsBlock';

export const getTagsFromSessionDetails = (sessionDetails: IWfaSessionDetails): ITagGroup[] => [
	{
		tags: [
			{
				name: 'Date',
				value: formatShortMonthDatetime(sessionDetails.runTime)
			},
			{
				name: 'Last modified',
				value: formatShortMonthDatetime(sessionDetails.lastModifiedTime)
			},
			{
				name: 'Created by',
				value: sessionDetails.createdBy
			},
			{
				name: 'Domain',
				value: sessionDetails.domain
			}
		]
	},
	{
		title: 'Session Settings',
		tags: [
			{
				name: 'Issue type',
				value: sessionDetails.detectionType
			},
			{
				name: 'Focus area',
				value: sessionDetails.focusArea
			}
		]
	}
];
