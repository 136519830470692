import { OptionType, TypesCountBySeverity } from '@evinced-private/ui-common';
import { buildRunIdParam } from 'src/helpers/GQLRequestsHelper/buildRunIdParam';
import { gqlClient } from '../../api/GraphQLRequestApi';
import { SummaryItem } from '../../interfaces/SummaryItem';
import { ITableQueryParams } from '../../types/TableQueryTypes';
import Logger from '../Logger';
import {
	IGeWfaSessionAllIssuesParams,
	IGeWfaSessionComponentsParams,
	TWfaSessionAllIssuesResult,
	TWfaSessionComponentsResult,
	IWfaSessionDetails,
	TWfaSessionTopComponentsResult,
	TWfaSessionsResult,
	TWfaSessionIssuesFilterOptions,
	SessionSummaryResult
} from './WfaDashboardService.types';
import { SeverityCountType } from '../../types/wfa/SeverityCountType';

import { WfaUsageData } from './queries/usage-data/WfaUsageData.gql';
import { WfaSessions } from './queries/sessions/WfaSessions.gql';
import { TopSessionComponents } from './queries/top-components/TopSessionComponents.gql';
import { WfaSessionSeverities } from './queries/session-severities/SessionSeverities.gql';

import { buildWfaUsageDataVariables } from './queries/usage-data/helpers/buildVariables';
import { SessionIssueTypes } from './queries/session-types/SessionIssueTypes.gql';
import { buildSessionsQueryVariables } from './queries/sessions/helpers/buildVariables';

import { transformWfaUsageDataResults } from './queries/usage-data/helpers/transformResults';
import { transformSessionsResults } from './queries/sessions/helpers/transformResults';
import { transformTopComponentsResult } from './queries/top-components/helpers/transformResults';
import { transformSessionSeveritiesResult } from './queries/session-severities/helpers/transformResults';
import { SessionIssueTypesQuery } from './queries/session-types/SessionIssueTypes.generated';
import { transformSessionIssueTypesBreakdownResult } from './queries/session-types/helpers/transformResults';

import { WfaUsageDataQuery } from './queries/usage-data/WfaUsageData.generated';
import { WfaSessionsQuery } from './queries/sessions/WfaSessions.generated';
import { TopSessionComponentsQuery } from './queries/top-components/TopSessionComponents.generated';
import { WfaSessionSeveritiesQuery } from './queries/session-severities/SessionSeverities.generated';
import { buildSessionsComponentsQueryVariables } from './queries/components/helpers/buildVariables';
import { WfaComponentsQuery } from './queries/components/WfaComponents.generated';
import { WfaComponents } from './queries/components/WfaComponents.gql';
import { transformSessionComponentsResults } from './queries/components/helpers/transformResults';

import { transformSessionDetailsResult } from './queries/session-details/helpers/transformResults';
import { WfaSessionDetailsQuery } from './queries/session-details/WfaSessionDetails.generated';
import { WfaSessionDetails } from './queries/session-details/WfaSessionDetails.gql';

import { buildSessionIssuesVariables } from './queries/session-issues/helpers/buildVariables';
import { transformSessionIssuesResults } from './queries/session-issues/helpers/transformResults';
import { WfaSessionIssuesQuery } from './queries/session-issues/WfaSessionIssues.generated';
import { WfaSessionIssues } from './queries/session-issues/WfaSessionIssues.gql';
import { WfaSessionIssuesFilterOptionsQuery } from './queries/session-issues-filter-options/WfaSessionIssuesFilterOptions.generated';
import { transformWfaSessionIssuesFilterOptionsResults } from './queries/session-issues-filter-options/helpers/transformResults';
import { WfaSessionIssuesFilterOptions } from './queries/session-issues-filter-options/WfaSessionIssuesFilterOptions.gql';

import { WfaSessionSummary } from './queries/session-summary/WfaSessionSummary.gql';
import { transformSessionSummaryResults } from './queries/session-summary/helpers/transformResults';

export const getWfaUsageData = async (
	testPeriod: OptionType,
	searchValue: string
): Promise<SummaryItem[]> => {
	try {
		const variables = buildWfaUsageDataVariables(testPeriod, searchValue);
		const result = await gqlClient.request<WfaUsageDataQuery>(WfaUsageData, variables);

		return transformWfaUsageDataResults(result, testPeriod);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaUsageData failed with an error:', e);
		throw e;
	}
};

export const getWfaSessions = async ({
	...restParams
}: ITableQueryParams): Promise<TWfaSessionsResult> => {
	try {
		const variables = buildSessionsQueryVariables(restParams);
		const result = await gqlClient.request<WfaSessionsQuery>(WfaSessions, variables);
		return transformSessionsResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessions failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionDetails = async (sessionId: string): Promise<IWfaSessionDetails> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<WfaSessionDetailsQuery>(WfaSessionDetails, variables);
		return transformSessionDetailsResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionDetails failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionAllIssues = async (
	params: IGeWfaSessionAllIssuesParams
): Promise<TWfaSessionAllIssuesResult> => {
	try {
		const variables = buildSessionIssuesVariables(params);
		const result = await gqlClient.request<WfaSessionIssuesQuery>(WfaSessionIssues, variables);
		return transformSessionIssuesResults(result);
	} catch (e) {
		Logger.error('getWfaSessionAllIssues -> getWfaSessionDetails failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionComponents = async (
	params: IGeWfaSessionComponentsParams
): Promise<TWfaSessionComponentsResult> => {
	try {
		const variables = buildSessionsComponentsQueryVariables(params);
		const result = await gqlClient.request<WfaComponentsQuery>(WfaComponents, variables);
		return transformSessionComponentsResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionComponents failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionTopComponents = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<TWfaSessionTopComponentsResult> => {
	try {
		const variables = buildRunIdParam(sessionId);

		const result = await gqlClient.request<TopSessionComponentsQuery>(
			TopSessionComponents,
			variables
		);

		return transformTopComponentsResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionTopComponents failed with an error:', e);
		throw e;
	}
};

export const getSessionSeverities = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<SeverityCountType[]> => {
	try {
		const variables = buildRunIdParam(sessionId);

		const result = await gqlClient.request<WfaSessionSeveritiesQuery>(
			WfaSessionSeverities,
			variables
		);

		return transformSessionSeveritiesResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionTopComponents failed with an error:', e);
		throw e;
	}
};

export const getSessionIssueTypes = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<TypesCountBySeverity[]> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<SessionIssueTypesQuery>(SessionIssueTypes, variables);
		return transformSessionIssueTypesBreakdownResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionTopComponents failed with an error:', e);
		throw e;
	}
};

export const getSessionSummary = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<SessionSummaryResult> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<SessionIssueTypesQuery>(WfaSessionSummary, variables);
		return transformSessionSummaryResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getSessionSummary failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionIssuesFilterOptions = async (
	runId: string
): Promise<TWfaSessionIssuesFilterOptions> => {
	try {
		const variables = buildRunIdParam(runId);

		const result = await gqlClient.request<WfaSessionIssuesFilterOptionsQuery>(
			WfaSessionIssuesFilterOptions,
			variables
		);

		return transformWfaSessionIssuesFilterOptionsResults(result);
	} catch (e) {
		Logger.error(
			'WfaDashboardService -> getWfaSessionIssuesFilterOptions failed with an error:',
			e
		);
		throw e;
	}
};
