// copied from semantic with minor changes
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { EvSection } from '@evinced-private/ui-common';
import UUIDHelper from 'src/helpers/UUIDHelper';
import './EvSummaryPane.scss';

type SummaryPaneItem = {
	value: string | number;
	label: string;
	link?: string;
};
interface ISummaryPaneProps {
	items: SummaryPaneItem[];
	sectionSrLabel?: string;
	className?: string;
	shouldHaveSkipLink?: boolean;
}

const SummaryPane: FC<ISummaryPaneProps> = ({
	items,
	className,
	sectionSrLabel,
	shouldHaveSkipLink = true
}: ISummaryPaneProps) => {
	const summaryPaneLabelId = UUIDHelper.generateUUID();
	const renderPaneContent = (value, label): JSX.Element => (
		<>
			<div
				className="value"
				aria-hidden="true"
				data-automation-id={`${label.toLowerCase().replace(/ /g, '-')}-summary-pane-value`}
			>
				{value}
			</div>
			<div className="label" aria-hidden="true" data-automation-id={label}>
				{label}
			</div>
		</>
	);

	const renderSummaryItem = ({ label, value, link }: SummaryPaneItem): JSX.Element => {
		return (
			<li
				className="ev-summary-pane-item"
				key={label}
				data-automation-id={`${label}-summary-pane-item`}
			>
				<div className="sr-only">{`${value} ${label}`}</div>
				{link ? (
					<Link to={link} aria-label={`${label} details`}>
						{renderPaneContent(value, label)}
					</Link>
				) : (
					renderPaneContent(value, label)
				)}
			</li>
		);
	};
	return (
		<EvSection
			ariaLabelledby={summaryPaneLabelId}
			skipLinkId={shouldHaveSkipLink ? 'summary' : null}
			className="ev-summary-pane-section"
		>
			{sectionSrLabel && (
				<h2 className="sr-only" id={summaryPaneLabelId}>
					{sectionSrLabel}
				</h2>
			)}
			<ul className={`ev-summary-pane ${className || ''}`}>{items.map(renderSummaryItem)}</ul>
		</EvSection>
	);
};

export default SummaryPane;
