import { OptionType } from '@evinced-private/ui-common';
import TYPE_TO_PRETTY_TYPE from '../consts/EvincedTypeNames';
import NotGroupedComponent from '../consts/NotGroupedComponent';
import CONFORMANCE_LEVELS from '../consts/ConformanceLevels';

function formatTypeLabel(option, isToUppercase = true): string {
	if (option in TYPE_TO_PRETTY_TYPE) {
		return TYPE_TO_PRETTY_TYPE[option];
	}
	if (option === NotGroupedComponent.ID) {
		return NotGroupedComponent.DISPLAY_NAME;
	}
	if (option in CONFORMANCE_LEVELS) {
		return CONFORMANCE_LEVELS[option];
	}
	if (isToUppercase) {
		return capitalizeFirstLetter(option);
	}
	return option;
}

function formatFilterOptionsForEvSelect(
	filterOptions: string[],
	labelPrefix = '',
	isToUppercase?: boolean
): OptionType[] {
	return filterOptions.map((option) => {
		return {
			value: option,
			label: labelPrefix + formatTypeLabel(option, isToUppercase)
		};
	});
}

const capitalizeFirstLetter = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const addPercentSign = (v: string): string => `${v}%`;

// general formatter. taken from https://stackoverflow.com/a/9462382
const nFormatter = (num, digits = 1): string => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' }
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

	let item;
	if (num <= 1) {
		item = lookup[0];
	} else {
		item = lookup
			.slice()
			.reverse()
			.find((item) => num >= item.value);
	}

	return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export default {
	formatFilterOptionsForEvSelect,
	capitalizeFirstLetter,
	addPercentSign,
	nFormatter
};
