// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-session-overview {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.wfa-session-overview .bottom-row-charts {
  display: flex;
  flex-grow: 0;
  overflow-x: hidden;
  gap: 30px;
}
.wfa-session-overview .bottom-row-charts .bottom-chart-section {
  width: 50%;
}
.wfa-session-overview .ev-chart-views .ev-chart-header .ev-chart-title-wrapper .chart-title h2,
.wfa-session-overview .ev-chart-views .ev-chart-header .ev-chart-title-wrapper .chart-title h3 {
  font-weight: 600;
}
.wfa-session-overview .custom-summary-pane {
  background: none;
  border: none;
  margin: 0;
  padding: 30px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/wfa-dashboard/single-session-page/views/overview/WfaSessionOverview.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AACD;AAAC;EACC,aAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;AAEF;AAAE;EACC,UAAA;AAEH;AAGE;;EAEC,gBAAA;AADH;AAKC;EACC,gBAAA;EACA,YAAA;EACA,SAAA;EACA,iBAAA;AAHF","sourcesContent":[".wfa-session-overview {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 30px;\n\t.bottom-row-charts {\n\t\tdisplay: flex;\n\t\tflex-grow: 0;\n\t\toverflow-x: hidden;\n\t\tgap: 30px;\n\n\t\t.bottom-chart-section {\n\t\t\twidth: 50%;\n\t\t}\n\t}\n\n\t.ev-chart-views .ev-chart-header .ev-chart-title-wrapper .chart-title {\n\t\th2,\n\t\th3 {\n\t\t\tfont-weight: 600;\n\t\t}\n\t}\n\n\t.custom-summary-pane {\n\t\tbackground: none;\n\t\tborder: none;\n\t\tmargin: 0;\n\t\tpadding: 30px 0 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
