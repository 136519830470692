import React from 'react';
import { EvButton } from '@evinced-private/ui-common';
import {
	WEB_SDK_NO_SYNCED_SESSIONS_MSG,
	WEB_SDK_NO_SYNCED_SESSIONS_TITLE
} from '../../dashboards-container/errorMessages';
import { WEB_SDK_DOCUMENTATION_LINK } from '../../dashboards-container/dashboard_consts';
import CloudStatusIcon from '../../../components/icons/CloudStatusIcon.svg';
import { WebSdkError } from './WebSdkError';

interface WebSDKErrorProps {
	isNoData?: boolean;
}
export const WebSdkTestsPageError: React.FC<WebSDKErrorProps> = ({ isNoData }) => {
	if (isNoData) {
		const button = (
			<EvButton
				className="documentation-link"
				onClick={() => window.open(WEB_SDK_DOCUMENTATION_LINK, '_blank')}
			>
				Read Documentation
			</EvButton>
		);

		return (
			<WebSdkError
				mainTitle={WEB_SDK_NO_SYNCED_SESSIONS_TITLE}
				secondaryMsg={WEB_SDK_NO_SYNCED_SESSIONS_MSG}
				icon={CloudStatusIcon}
				button={button}
			/>
		);
	}

	return <WebSdkError icon={CloudStatusIcon} />;
};
