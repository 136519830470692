import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ShowMoreButton } from '../show-more-button/ShowMoreButton';
import './CollapsiblePane.scss';

interface CollapsiblePaneProps {
	className?: string;
	containerClassName?: string;
	maxHeight?: number;
	collapseTitle?: string;
	expandTitle?: string;
}

export const CollapsiblePane: React.FC<CollapsiblePaneProps> = ({
	containerClassName,
	className,
	maxHeight = 230,
	children,
	collapseTitle,
	expandTitle
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const container = containerRef.current;
		const calculateShowButton = (): void => {
			setShowButton((container?.scrollHeight ?? 0) > (container?.offsetHeight ?? 0));
		};

		calculateShowButton();
		container?.addEventListener('resize', calculateShowButton);

		return () => container?.removeEventListener('resize', calculateShowButton);
	}, []);

	return (
		<div className={classNames('collapsible-pane', className)}>
			<div
				ref={containerRef}
				className={classNames('collapsible-container', containerClassName)}
				style={{ maxHeight: isOpen ? 'fit-content' : maxHeight }}
			>
				{children}
			</div>
			{showButton && (
				<ShowMoreButton
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					collapseTitle={collapseTitle}
					expandTitle={expandTitle}
				/>
			)}
		</div>
	);
};
