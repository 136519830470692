import React, { FC } from 'react';
import { EvTooltip } from '@evinced-private/ui-common';
import classNames from 'classnames';
import UUIDHelper from '../../../helpers/UUIDHelper';
import { TABLE_LABEL_DAID } from '../../../consts/data-automation-ids';
import './DashboardTableLabel.scss';

const SHOW_ELLIPSIS_LENGTH = 18;

interface IDashboardTableLabelProps {
	labels: string[];
}

const DashboardTableLabel: FC<IDashboardTableLabelProps> = ({ labels }) => {
	const labelsCount = labels.length;
	const extraLabelsCount = labelsCount - 5;
	const labelsToRender = labels.slice(0, 5);
	return (
		<div className="table-labels">
			{labelsToRender.map((label) => {
				const showTooltip = label.length > SHOW_ELLIPSIS_LENGTH;
				const uuid = UUIDHelper.generateUUID();
				const tooltipId: string = showTooltip ? uuid : undefined;

				return label && label.trim() ? (
					<div className="table-label" key={uuid} data-automation-id={TABLE_LABEL_DAID}>
						<span
							data-tooltip-id={tooltipId}
							data-tooltip-content={showTooltip ? label : undefined}
						>
							{label}
						</span>
						{showTooltip && <EvTooltip id={tooltipId} place="bottom" type="info" />}
					</div>
				) : null;
			})}
			{extraLabelsCount > 0 && (
				<div className={classNames('table-label', 'extra-label')}>{`+${extraLabelsCount}`}</div>
			)}
		</div>
	);
};

export default DashboardTableLabel;
