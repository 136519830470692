// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-details-cell-formatter .error-popup .error-card {
  border: none;
  box-shadow: none;
  padding: 0 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/show-details-cell-formatter/ShowDetailsCellFormatter.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;EACA,gBAAA;EACA,eAAA;AADN","sourcesContent":[".show-details-cell-formatter {\r\n  .error-popup {\r\n    .error-card {\r\n      border: none;\r\n      box-shadow: none;\r\n      padding: 0 30px;\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
