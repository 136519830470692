import { TWfaSessionTopComponentsResult } from '../../../WfaDashboardService.types';
import { TopSessionComponentsQuery } from '../TopSessionComponents.generated';

export const transformTopComponentsResult = (
	rawResults: TopSessionComponentsQuery
): TWfaSessionTopComponentsResult => {
	return (
		rawResults?.wfa_top_critical_components?.map((c) => ({
			componentId: c.elements__component_id,
			percentage: Number(c.critical_percentage?.toFixed(2))
		})) || []
	);
};
