/* eslint-disable @typescript-eslint/no-explicit-any */
import ServerApi from 'src/api/ServerApi';
import API_URL_PATHS from '../../consts/ApiUrlPaths';
import { ProductType, TenantProduct } from '../../interfaces/Tenant';
import { TConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import Logger from '../Logger';

const { CENTRAL_CONFIG_PATH } = API_URL_PATHS;

const getPlatformProductID = (products: TenantProduct[]): string => {
	if (!products) {
		return null;
	}
	return products.find(
		(product: TenantProduct) => product.type === ProductType.PLATFORM
	)?.id;
};

const getCentralConfig = async (
	products: TenantProduct[]
): Promise<TConfiguration> => {
	const platformProductID = getPlatformProductID(products);
	if (platformProductID) {
		try {
			const configurationResult: TConfiguration = await ServerApi.apiCall(
				'GET',
				`${CENTRAL_CONFIG_PATH}?productId=${platformProductID}`,
				{ isConfigUrl: true }
				);

			return configurationResult;
		} catch (error) {
			Logger.error('Error fetching central configuration', error);
			return null;
		}
	}
	return null;
};

export default {
	getCentralConfig
};
