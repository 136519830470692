import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { EvLinkNavigationTabs, IMenuOption } from '@evinced-private/ui-common';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { TABS_DAID } from 'src/consts/data-automation-ids';
import { SessionViews } from '../WfaDashboardConsts';

interface IWfaSessionTabsProps {
	viewId?: SessionViews;
	sessionId: string;
}

export const WfaSingleSessionTabs: FC<IWfaSessionTabsProps> = ({
	sessionId,
	viewId = SessionViews.OVERVIEW
}) => {
	const history = useHistory();

	const handleTabClick = useCallback(
		(tabViewId: SessionViews): void =>
			history.push(RoutesHelper.getWfaSessionPath(sessionId, tabViewId), {
				from: history.location.pathname
			}),
		[history, sessionId]
	);

	const options: IMenuOption[] = useMemo(
		() => [
			{
				id: SessionViews.OVERVIEW,
				title: 'Overview',
				onClick: () => handleTabClick(SessionViews.OVERVIEW)
			},
			{
				id: SessionViews.COMPONENTS,
				title: 'Components',
				onClick: () => handleTabClick(SessionViews.COMPONENTS)
			},
			{
				id: SessionViews.ALL_ISSUES,
				title: 'All Issues',
				onClick: () => handleTabClick(SessionViews.ALL_ISSUES)
			}
		],
		[handleTabClick]
	);

	return (
		<div data-automation-id={TABS_DAID}>
			<EvLinkNavigationTabs selectedTabId={viewId} options={options} />
		</div>
	);
};
