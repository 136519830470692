import {
	IFilterDefinition,
	ISelectFilterDefinition,
	ITextFilterDefinition,
	OptionType
} from '@evinced-private/ui-common';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';

export const WEB_SDK_TEST_ID_PARAM = 'runId';
export const WEB_SDK_TEST_NAME_PARAM = 'testName';
export const WEB_SDK_TEST_RUN_TIME_PARAM = 'runTime';

export const NAME_NOT_PROVIDED = 'Name-not-Provided';

export const MAIN_PAGE_NAV_LABEL = 'All Tests';

export const DEFAULT_PERIOD_OPTION: OptionType = { value: 30, label: 'Last 30 days' };

export const TESTS_REFETCH_INTERVAL = 120000;

export const TESTS_PERIOD_OPTIONS: OptionType[] = [
	DEFAULT_PERIOD_OPTION,
	{ value: 60, label: 'Last 60 days' },
	{ value: 90, label: 'Last 90 days' },
	{ value: 'all', label: 'All Tests' }
];

const enableWebSdkProperties = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES
);

const searchFilter: ITextFilterDefinition = {
	id: 'searchValue',
	label: 'Search',
	filterType: 'text',
	defaultValue: '',
	componentProps: {
		placeholder: 'Search'
	}
};

const propertiesFilter: ISelectFilterDefinition = {
	id: 'properties',
	label: 'properties',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select Property',
		allSelectedPlaceholder: 'All Properties',
		someItemsSelectedText: (numberOfItems) => `Properties (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'properties-filter-dropdown'
	}
};

export const webSdkTestsFilterDefinitions: IFilterDefinition[] = [
	searchFilter,
	...(enableWebSdkProperties ? [propertiesFilter] : []),
	{
		id: 'labels',
		label: 'labels',
		filterType: 'select',
		defaultValue: [],
		componentProps: {
			placeholder: 'Select Label',
			allSelectedPlaceholder: 'All Labels',
			someItemsSelectedText: (numberOfItems) => `Labels (${numberOfItems})`,
			allowSelectAll: true,
			isMulti: true,
			className: 'labels-filter-dropdown'
		}
	} as ISelectFilterDefinition,
	{
		id: 'testPeriod',
		label: 'tests period',
		filterType: 'select',
		defaultValue: [DEFAULT_PERIOD_OPTION],
		componentProps: {
			placeholder: 'Select Tests Period',
			options: TESTS_PERIOD_OPTIONS,
			isMulti: false
		}
	} as ISelectFilterDefinition
];

export const webSdkIssuesFilterDefinitions: IFilterDefinition[] = [
	searchFilter,
	...(enableWebSdkProperties ? [propertiesFilter] : []),
	{
		id: 'urls',
		label: 'url',
		filterType: 'select',
		defaultValue: [],
		componentProps: {
			placeholder: 'Select URL',
			allSelectedPlaceholder: 'All URLs',
			someItemsSelectedText: (numberOfItems) => `URLs (${numberOfItems})`,
			allowSelectAll: true,
			isMulti: true,
			className: 'url-filter-dropdown'
		}
	} as ISelectFilterDefinition,
	{
		id: 'severities',
		label: 'severities',
		filterType: 'select',
		defaultValue: [],
		componentProps: {
			placeholder: 'Select Severity',
			allSelectedPlaceholder: 'All Severities',
			someItemsSelectedText: (numberOfItems) => `Severities (${numberOfItems})`,
			allowSelectAll: true,
			isMulti: true
		}
	} as ISelectFilterDefinition,
	{
		id: 'types',
		label: 'types',
		filterType: 'select',
		defaultValue: [],
		componentProps: {
			placeholder: 'Select Type',
			allSelectedPlaceholder: 'All Types',
			someItemsSelectedText: (numberOfItems) => `Types (${numberOfItems})`,
			allowSelectAll: true,
			isMulti: true
		}
	} as ISelectFilterDefinition,
	{
		id: 'levels',
		label: 'levels',
		filterType: 'select',
		defaultValue: [],
		componentProps: {
			placeholder: 'Select Level',
			allSelectedPlaceholder: 'All Levels',
			someItemsSelectedText: (numberOfItems) => `Levels (${numberOfItems})`,
			allowSelectAll: true,
			isMulti: true
		}
	}
];
