// This file was copied from site scanner. Planned to move it to ui-common
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
	EvLink,
	EvSection,
	EvSpinner,
	EvTitle,
	ScreenshotDetails,
	TITLE_MODES
} from '@evinced-private/ui-common';
import { screenshotService } from 'src/services/ScreenshotService';
import logger from 'src/services/Logger';
import IssueMarkdownTextBlock, {
	renderPart
} from '../issue-markdown-text-block/IssueMarkdownTextBlock';
import { CollapsiblePane } from '../collapsible-pane/CollapsiblePane';
import { LabelsBlock } from '../../labels-block/LabelsBlock';
import { KnowledgeBaseLink } from './KnowledgeBaseLink';
import { HowToFixBlock } from './HowToFixBlock';
import { WcagTagsLinksBlock } from './WcagTagsLinksBlock';
import { ElementDetailsBlock } from './ElementDetailsBlock';
import { IssueDetailsBlockSingleIssueData } from './DetailsBlockTypes';
import './DetailsBlock.scss';

interface IDetailsProps {
	issue: IssueDetailsBlockSingleIssueData;
	topRowActions?: ReactNode;
	isLoading?: boolean;
	isFullPageMode?: boolean;
}

const LABELS_SECTION_ID = 'labels-section-title';

const DetailsBlock: FC<IDetailsProps> = ({
	issue,
	isLoading,
	topRowActions,
	isFullPageMode
}: IDetailsProps) => {
	const [image, setImage] = useState<string>(null);
	const subheadingsLevel = useMemo(() => (isFullPageMode ? 2 : 3), [isFullPageMode]);
	const resolveImage = useCallback(
		async (screenshotDescriptor: ScreenshotDetails): Promise<void> => {
			try {
				const screenshotImageData = await screenshotService.getFullPageScreenshotWithIssues(
					screenshotDescriptor
				);
				setImage(screenshotImageData);
			} catch (e) {
				logger.warn(e);
				setImage(null);
			}
		},
		[]
	);

	const {
		type,
		severity,
		elements,
		level,
		labels,
		summary,
		cause,
		howToFix,
		effect,
		knowledgeBaseLink,
		tags,
		pageUrl,
		pageTitle,
		screenshotDetails
	} = issue;

	useEffect(() => {
		if (!image && screenshotDetails) {
			resolveImage(screenshotDetails);
		}
	}, [screenshotDetails, image, resolveImage]);

	if (isLoading) {
		return (
			<div className="spinner-wrapper">
				<EvSpinner />
			</div>
		);
	}
	if (!type) {
		return null;
	}
	return (
		<div className="details-content">
			<div className="section-header">
				<div className={classNames('title-block', { 'with-hidden-title': !isFullPageMode })}>
					{isFullPageMode && (
						<EvTitle titleText={type || ''} headingLevel={1} mode={TITLE_MODES.MAIN} />
					)}
					{topRowActions}
				</div>
				<div className="sub-title">{summary}</div>
			</div>

			<div className="element-details">
				<div className="details-block">
					{pageUrl
						? renderPart(
								'URL:',
								<EvLink url={pageUrl} showIcon={true} openInNewTab={true} />,
								subheadingsLevel
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  )
						: null}
					{pageTitle ? renderPart('Page Title:', pageTitle, subheadingsLevel) : null}
				</div>
				<div className="details-block">
					{elements.map((element) => (
						<ElementDetailsBlock
							elementId={element.id}
							componentId={element.componentId}
							selector={element.selector}
							subheadingsLevel={subheadingsLevel}
							domSnippet={element.domSnippet}
						/>
					))}
				</div>
				{labels?.length ? (
					<>
						<EvTitle
							titleText={`Labels (${labels.length})`}
							id={LABELS_SECTION_ID}
							mode={TITLE_MODES.NORMAL}
							className="subheading"
							headingLevel={subheadingsLevel}
						/>
						<EvSection ariaLabelledby={LABELS_SECTION_ID}>
							<CollapsiblePane
								className="labels-collapsible-pane"
								containerClassName="labels-section"
								collapseTitle="Show less labels"
								expandTitle="Show more labels"
							>
								<LabelsBlock labels={labels} isThreeColumns />
							</CollapsiblePane>
						</EvSection>
					</>
				) : null}
				<div className="details-block dual">
					<div className="half-block">
						<div className="severity-level">
							{severity ? renderPart('Severity:', severity, subheadingsLevel) : null}
							{level ? renderPart('Level:', level, subheadingsLevel) : null}
							<IssueMarkdownTextBlock
								sectionName="Cause:"
								stringToRender={cause}
								headingLevel={subheadingsLevel}
							/>
							<IssueMarkdownTextBlock
								sectionName="Effect:"
								stringToRender={effect}
								headingLevel={subheadingsLevel}
							/>
							<KnowledgeBaseLink link={knowledgeBaseLink} />
						</div>
					</div>
					<div className="half-block">
						<HowToFixBlock howToFix={howToFix} subheadingsLevel={subheadingsLevel} />
						<WcagTagsLinksBlock tags={tags} subheadingsLevel={subheadingsLevel} />
					</div>
				</div>
			</div>
			{image && (
				<>
					<EvTitle
						titleText="Screenshot"
						mode={TITLE_MODES.NORMAL}
						className="subheading"
						headingLevel={subheadingsLevel}
					/>
					<div className="screenshot-picture">
						<img className="screenshot-img" src={image} alt="Issue Screenshot" />
					</div>
				</>
			)}
		</div>
	);
};

export default DetailsBlock;
