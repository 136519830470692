import { EvIcon } from '@evinced-private/ui-common';
import React from 'react';
import RoutesHelper from '../../helpers/RoutesHelper';
import EvLinkLocal from '../../components/common/ev-link-local/EvLinkLocal';
import NotFoundIcon from '../../components/icons/NotFoundIcon.svg';
import './NotFound404Page.scss';

export default ({ redirectTo = RoutesHelper.getHomepagePath() }): JSX.Element => {
	return (
		<div className="error-404">
			<div className="error-404-content">
				<EvIcon icon={NotFoundIcon} />
				<div className="text-messge">
					<p>Sorry, we couldn&apos;t find what you were looking for...</p>
					<EvLinkLocal url={redirectTo} className="back" linkText="Back to Dashboard page" />
				</div>
			</div>
		</div>
	);
};
