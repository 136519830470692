// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipsed-text {
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/ellipsed-text-with-tooltip/EllipsedTextWithTooltip.scss","webpack://./node_modules/@evinced-private/ui-common/dist/styles/ellipsis-mixins.scss"],"names":[],"mappings":"AAEA;EACC,kBAAA;ECFA,gBAAA;EACA,uBAAA;EACA,mBAAA;ADED","sourcesContent":["@import '~@evinced-private/ui-common/dist/styles/ellipsis-mixins.scss';\n\n.ellipsed-text {\n\tmargin-right: 10px;\n\t@include ellipsis();\n}\n","@mixin ellipsis() {\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
