const SELECT_ALL_LABEL = 'Select all';
export const SELECT_ALL_VALUE = '*';
export const SELECT_ALL_OPTION = { value: SELECT_ALL_VALUE, label: SELECT_ALL_LABEL };

export enum FilterTypesNames {
	SEARCH_VALUE = 'searchValue',
	LABELS = 'userLabels',
	COMPONENTS = 'components',
	TYPES = 'types',
	SEVERITIES = 'severities',
	URLS = 'urls',
	TAGS = 'tags',
	OS = 'os',
	APPS = 'apps',
	VERSIONS = 'versions'
}
