import React from 'react';
import { EvTableColumn, TypesCellFormatter } from '@evinced-private/ui-common';
import { IWfaSessionComponent } from 'src/services/wfa-dashboard/WfaDashboardService.types';
import { formatDate } from 'src/helpers/DateFormatHelper';
import { NAME_NOT_PROVIDED, SessionViews } from 'src/pages/wfa-dashboard/WfaDashboardConsts';
import { ComponentCellFormatter } from 'src/components/component-cell-formatter/ComponentCellFormatter';
import { WFA_SESSION_COMPONENTS_TABLE_ID } from 'src/pages/dashboards-container/dashboard_consts';
import RoutesHelper from 'src/helpers/RoutesHelper';
import ShowDetailsCellFormatter from '../../../../../components/common/show-details-cell-formatter/ShowDetailsCellFormatter';
import { ProductType } from '../../../../../interfaces/Tenant';

export const getWfaComponentsColumns = (
	sessionId: string,
	onComponentClick: (componentId: string) => void
): EvTableColumn[] => {
	return [
		{
			dataField: 'id',
			text: 'Component',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: true,
			formatter: (componentId: string) => (
				<ComponentCellFormatter
					componentId={componentId}
					tableId={WFA_SESSION_COMPONENTS_TABLE_ID}
					url={RoutesHelper.getWfaSessionPath(sessionId, SessionViews.ALL_ISSUES)}
					onClick={() => onComponentClick(componentId)}
				/>
			)
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: true
		},
		{
			dataField: 'pagesImpacted',
			text: 'Pages Impacted',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: true
		},
		{
			dataField: 'types',
			text: 'Issue Type',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: false,
			formatter: (types: string[]) => <TypesCellFormatter validationTypes={types} />
		},
		{
			dataField: '',
			text: '',
			style: { width: '80px', paddingRight: '24px', paddingLeft: '24px' },
			headerStyle: { width: '80px' },
			formatter: (cell, { id }: IWfaSessionComponent) => {
				return (
					// TODO: Replace with component details popup
					<ShowDetailsCellFormatter
						product={ProductType.WEB_FLOW_ANALYZER}
						runId={sessionId}
						issueId={id}
						runTime=""
						testName=""
						issueType=""
						infoTitle={`${'sessionName' || NAME_NOT_PROVIDED} session at ${formatDate('runTime')}`}
					/>
				);
			}
		}
	];
};
