import React, { FC, useState } from 'react';
import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';
import IssueInfoIcon from '../../icons/IssueInfoIcon.svg';
import DetailsPopup from '../details-popup/DetailsPopup';
import { ProductType } from '../../../interfaces/Tenant';
import './ShowDetailsCellFormatter.scss';

interface IShowDetailsFormatterCell {
	product: ProductType;
	runId: string;
	issueId: string;
	infoTitle: string;
	runTime: string;
	testName?: string;
	issueType?: string;
}

const ShowDetailsCellFormatter: FC<IShowDetailsFormatterCell> = ({
	product,
	runId,
	issueId,
	infoTitle,
	runTime,
	testName,
	issueType
}: IShowDetailsFormatterCell) => {
	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = async (): Promise<void> => {
		setModalOpen(true);
	};

	return (
		<div className="show-details-cell-formatter">
			<EvButton
				title={infoTitle}
				customTooltip={{ renderTooltip: true }}
				type={BUTTON_TYPES.ICON}
				className="show-details-button"
				onClick={openModal}
				accessibilityProps={{
					'aria-label': 'Show issue details'
				}}
				ariaHaspopup="dialog"
			>
				<EvIcon icon={IssueInfoIcon} />
			</EvButton>
			{isModalOpen && (
				<DetailsPopup
					product={product}
					runId={runId}
					issueId={issueId}
					runTime={runTime}
					testName={testName}
					issueType={issueType}
					onClose={() => setModalOpen(false)}
				/>
			)}
		</div>
	);
};

export default ShowDetailsCellFormatter;
