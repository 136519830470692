import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { SessionViews } from '../WfaDashboardConsts';
import { WfaSingleSessionPage } from './WfaSingleSessionPage';

export const WfaSingleSessionRouter: FC = () => {
	const history = useHistory();
	const basePath = `${RoutesHelper.getWfaSessionBasePath()}/:sessionId`;
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={`${basePath}/overview`}
					render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />}
				/>
				<Route
					exact
					path={`${basePath}/components`}
					render={() => <WfaSingleSessionPage viewId={SessionViews.COMPONENTS} />}
				/>
				<Route
					exact
					path={`${basePath}/all-issues`}
					render={() => <WfaSingleSessionPage viewId={SessionViews.ALL_ISSUES} />}
				/>
				<Route
					exact
					path={basePath}
					render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />}
				/>
				<Route render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />} />
			</Switch>
		</Router>
	);
};
